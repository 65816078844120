import fetchClient from "@/utils/fetchClient"
import fetchData from "../fetchData"
import Severity from "@/utils/serviceSeverity"

export default {
  state: {
    machineDashboard: null, // Data for machine dashboard
    machineEngineHoursSummary: null, // Data for Engine Hours widget on Machine Dashboard
    machineActivity: null, // Data for Machine and Tool Activity widget on Machine Dashboard
    machineInfo: null, // Information about the machine, segment, warranty, etc.
    machineParameters: [],
    machineTransfer: null,

    loadingMachineDashboard: false,
    loadingMachineEngineHoursSummary: false,
    loadingMachineActivity: false,
    loadingMachineInfo: false,
    loadingMachineParameters: false,

    loadingMachines: false,

    serviceAgreement: {},
    machinesUtilizations: []
  },
  mutations: {
    setMachineDashboard(state, payload) {
      state.machineDashboard = payload
      state.machineEngineHoursSummary = {
        total: payload.timing.engineTime.data,
        lastDay: null,
        lastWeek: null,
        lastMonth: null
      }
    },
    setLoadingMachineDashboard(state, payload) {
      state.loadingMachineDashboard = payload
    },
    setLoadingMachineActivity(state, payload) {
      state.loadingMachineActivity = payload
    },
    updateMachineActivity(state, payload) {
      state.machineActivity = payload
    },
    updateMachineEngineHoursSummary(state, payload) {
      state.machineEngineHoursSummary.lastDay = payload.lastDay
        ? state.machineEngineHoursSummary.total - payload.lastDay
        : 0
      state.machineEngineHoursSummary.lastWeek = payload.lastWeek
        ? state.machineEngineHoursSummary.total - payload.lastWeek
        : 0
      state.machineEngineHoursSummary.lastMonth = payload.lastMonth
        ? state.machineEngineHoursSummary.total - payload.lastMonth
        : 0
    },
    setLoadingMachineEngineHoursSummary(state, payload) {
      state.loadingMachineEngineHoursSummary = payload
    },
    setMachineInfo(state, payload) {
      state.machineInfo = payload
    },
    setLoadingMachineInfo(state, payload) {
      state.loadingMachineInfo = payload
    },
    setMachineParameters(state, payload) {
      state.machineParameters = payload
    },
    setMachineTransfer(state, payload) {
      state.machineTransfer = payload
    },
    setLoadingMachineParameters(state, payload) {
      state.loadingMachineParameters = payload
    },
    setProductId(state, payload) {
      state.productId = payload
    },
    setLoadingMachines(state, payload) {
      state.loadingMachines = payload
    },
    setServiceAgreement(state, payload) {
      state.serviceAgreement = payload
    },
    setMachinesUtilizations(state, payload) {
      state.machinesUtilizations = payload
    }
  },
  actions: {
    addMachine(context, data) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)

        fetchClient(this.state.$i18n)
          .post(`/api/v1/tenants/${data.tenantUid}/unprovisionedmachines`, {
            serialNo: data.serialNo,
            productId: data.productId,
            engineHours: parseInt(data.engineHours)
          })
          .then(() => {
            resolve({})
          })
          .catch(error => {
            var errMsg = error.message

            if (error.causedBy) {
              errMsg = error.toString()
            }
            reject(errMsg)
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },

    fetchMachines(context, tenantUid) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)
        context.commit("setLoadingMachines", true)

        context
          .dispatch(
            "machinesListStore/fetchData",
            () =>
              fetchClient(this.state.$i18n).get(
                `/api/v1/tenants/${tenantUid}/machines`
              ),
            { root: true }
          )
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoading", false)
            context.commit("setLoadingMachines", false)
            resolve() //Nothing to do after all so always resolve promise
          })
      })
    },
    createMachine(context, machine) {
      context.commit("setLoading", true)

      fetchClient(this.state.$i18n)
        .post(`/api/v1/tenants/${machine.tenantUid}/machines`, machine)
        .then(() => {
          // Refresh the machine list to get calculated fields added by the backend.
          context.dispatch("fetchMachines", machine.tenantUid)
        })
        .catch(error => {
          context.commit("setError", error)
        })
        .finally(() => {
          context.commit("setLoading", false)
        })
    },
    updateMachine(context, machine) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)

        fetchClient(this.state.$i18n)
          .put(
            `/api/v1/tenants/${machine.orgTenantUid}/machines/${machine.serialNo}`,
            machine
          )
          .then(() => {
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject()
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },
    fetchMachineDashboard(context, machine) {
      context.commit("setLoadingMachineDashboard", true)
      fetchClient(this.state.$i18n)
        .get(
          `/api/v1/tenants/${machine.tenantUid}/machines/${machine.serialNo}/dashboard`
        )
        .then(data => {
          context.commit("setMachineDashboard", data)
        })
        .catch(error => {
          context.commit("setError", error)
        })
        .finally(() => {
          context.commit("setLoadingMachineDashboard", false)
        })
    },

    fetchMachineActivity(context, { machine, params }) {
      context.commit("setLoadingMachineActivity", true)

      fetchClient(this.state.$i18n)
        .get(
          `/api/v1/tenants/${machine.tenantUid}/machines/${machine.serialNo}/dashboard/activity?startDate=${params.start}&endDate=${params.end}`
        )

        .then(data => {
          context.commit("updateMachineActivity", data)
        })
        .catch(error => {
          context.commit("setError", error)
        })
        .finally(() => {
          context.commit("setLoadingMachineActivity", false)
        })
    },

    fetchMachineEngineHoursSummary(context, machine) {
      context.commit("setLoadingMachineEngineHoursSummary", true)
      fetchClient(this.state.$i18n)
        .get(
          `/api/v1/tenants/${machine.tenantUid}/machines/${machine.serialNo}/dashboard/enginehours`
        )
        .then(data => {
          context.commit("updateMachineEngineHoursSummary", data)
        })
        .catch(error => {
          context.commit("setError", error)
        })
        .finally(() => {
          context.commit("setLoadingMachineEngineHoursSummary", false)
        })
    },

    fetchMachineInfo(context, machine) {
      context.commit("setLoadingMachineInfo", true)
      fetchClient(this.state.$i18n)
        .get(
          `/api/v1/tenants/${machine.tenantUid}/machines/${machine.serialNo}/info`
        )
        .then(data => {
          context.commit("setMachineInfo", data)
        })
        .catch(error => {
          if (error.status === 404) {
            context.commit("setMachineInfo", null)
          } else {
            context.commit("setError", error)
          }
        })
        .finally(() => {
          context.commit("setLoadingMachineInfo", false)
        })
    },

    fetchMachineParameters(context, machine) {
      context.commit("setLoadingMachineParameters", true)
      fetchClient(this.state.$i18n)
        .get(
          `/api/v1/tenants/${machine.tenantUid}/machines/${machine.serialNo}/parameters`
        )
        .then(data => {
          context.commit("setMachineParameters", data)
        })
        .catch(error => {
          context.commit("setError", error)
        })
        .finally(() => {
          context.commit("setLoadingMachineParameters", false)
        })
    },

    fetchTransferByCode(context, transfer) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)

        fetchClient(this.state.$i18n)
          .get(`/api/v1/machines/transfers/${transfer.code}`)
          .then(data => {
            context.commit("setMachineTransfer", data)
            resolve(data)
          })
          .catch(error => {
            if (error && error.status == 404) {
              // Safe to ignore, no record was found
              context.commit("setMachineTransfer", null)
              resolve(null)
            } else {
              context.commit("setError", error)
              reject()
            }
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },

    transferMachine(context, transfer) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)

        fetchClient(this.state.$i18n)
          .post(`/api/v1/machines/transfers/${transfer.code}`, {
            dstTenantUid: transfer.dstTenantUid
          })
          .then(() => {
            resolve({})
          })
          .catch(error => {
            var errMsg = error.message

            if (error.causedBy) {
              errMsg = error.toString()
            }
            reject(errMsg)
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },

    fetchMachineTransfer(context, machine) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)

        fetchClient(this.state.$i18n)
          .get(`/api/v1/machines/${machine.serialNo}/transfers?q=pending`)
          .then(data => {
            if (data.length > 0) {
              const machineTransfer = data[0]
              context.commit("setMachineTransfer", machineTransfer)
              resolve(machineTransfer)
            } else {
              // No pending transfer
              context.commit("setMachineTransfer", null)
              resolve(null)
            }
          })
          .catch(error => {
            context.commit("setError", error)
            reject()
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },

    createMachineTransfer(context, machineTransfer) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)

        const { machine, transfer } = machineTransfer

        fetchClient(this.state.$i18n)
          .post(`/api/v1/machines/${machine.serialNo}/transfers`, transfer)
          .then(data => {
            context.commit("setMachineTransfer", data)
            resolve(data)
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },

    commissionMachine(context, commissioningReq) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)
        const { machine, commissioning } = commissioningReq

        fetchClient(this.state.$i18n)
          .putOrThrow(
            `/api/v1/tenants/${machine.tenantUid}/machines/${machine.serialNo}/info
          `,
            commissioning
          )
          .then(() => {
            resolve({})
          })
          .catch(error => {
            reject(error)
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },
    fetchServiceAgreement(context, machine) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)

        fetchClient(this.state.$i18n)
          .get(
            `/api/v1/tenants/${machine.tenantUid}/machines/${machine.serialNo}/maintenance/agreement`
          )
          .then(data => {
            context.commit("setServiceAgreement", data)
            resolve(data)
          })
          .catch(error => {
            context.commit("setError", error)
            reject()
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },
    removeServiceAgreement(context, machine) {
      context.commit("setLoading", true)

      return new Promise((resolve, reject) => {
        fetchClient(this.state.$i18n)
          .delete(
            `/api/v1/tenants/${machine.tenantUid}/machines/${machine.serialNo}/maintenance/agreement`
          )
          .then(() => {
            context.commit("setServiceAgreement", null)
            resolve()
          })
          .catch(error => {
            context.commit("setError", error)
            reject(error)
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    },
    fetchMachinesUtilizations(context, params) {
      return new Promise((resolve, reject) => {
        context.commit("setLoading", true)

        const { tenantUid, start, end } = params

        fetchClient(this.state.$i18n)
          .get(
            `/api/v1/tenants/${tenantUid}/fleetAnalytics/utilization?start=${start}&end=${end}`
          )
          .then(data => {
            context.commit("setMachinesUtilizations", data)
            resolve(data)
          })
          .catch(error => {
            context.commit("setError", error)
            reject()
          })
          .finally(() => {
            context.commit("setLoading", false)
          })
      })
    }
  },
  getters: {
    machines(state) {
      return state.machinesListStore.data
    },
    loadingMachines(state) {
      return state.loadingMachines
    },
    machinesFilteredByTenants(state) {
      return tenantArray => {
        let machines = []

        if (state.machinesListStore.data !== null) {
          machines = state.machinesListStore.data.filter(machine =>
            tenantArray.find(tenant => machine.tenantUid === tenant.uid)
          )
        }

        machines.map(function(a) {
          if (
            a.productName == "Brokk 520DIII" ||
            a.productName == "Brokk 520DIV"
          ) {
            a.productName = "Brokk 520D"
          }
          a.maxSeverity = Severity.level(a)
        })

        return machines
      }
    },
    machineBySerialNo(state) {
      return serialNo => {
        let machine = null
        if (state.machinesListStore.data !== null) {
          machine = state.machinesListStore.data.find(
            m => m.serialNo === serialNo
          )
        }
        return machine
      }
    },
    machineDashboard(state) {
      return state.machineDashboard
    },
    loadingMachineDashboard(state) {
      return state.loadingMachineDashboard
    },
    machineActivity(state) {
      return state.machineActivity
    },
    loadingMachineActivity(state) {
      return state.loadingMachineActivity
    },
    machineInfo(state) {
      return state.machineInfo
    },
    loadingMachineInfo(state) {
      return state.loadingMachineInfo
    },
    machineParameters(state) {
      return state.machineParameters
    },
    machineTransfer(state) {
      return state.machineTransfer
    },
    machineEngineHoursSummary(state) {
      return state.machineEngineHoursSummary
    },
    loadingMachineEngineHoursSummary(state) {
      return state.loadingMachineEngineHoursSummary
    },
    serviceAgreement(state) {
      return state.serviceAgreement
    },
    machinesUtilizations(state) {
      return state.machinesUtilizations
    }
  },
  modules: {
    machinesListStore: fetchData
  }
}
