<template>
  <v-card flat outlined class="mt-2">
    <v-card-title
      >{{ $t("machines.notifications.settings.title") }}
      <v-spacer></v-spacer>

      <v-tooltip top v-if="canEditItem && !disabled">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-icon
              :disabled="editNotification || isSendingNotification"
              @click="editNotification = true"
              >mdi-pencil</v-icon
            >
          </span>
        </template>
        <span>{{ $t("machines.notifications.settings.actions.edit") }}</span>
      </v-tooltip>

      <v-tooltip top v-if="notification && canRemoveItem">
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <v-icon
              :disabled="editNotification || isSendingNotification"
              class="ml-2"
              @click.stop="showConfirmRemove = true"
              >mdi-delete</v-icon
            >
          </span>
        </template>
        <span>{{ $t("machines.notifications.settings.actions.remove") }}</span>
      </v-tooltip>
    </v-card-title>
    <v-card-subtitle>
      {{ $t("machines.notifications.settings.description") }}
    </v-card-subtitle>
    <v-card-text class="black--text">
      <v-card flat outlined>
        <!-- info window when no lease has been defined yet -->
        <v-overlay
          v-if="disabled"
          absolute
          color="#fff"
          opacity=".9"
          class="text--primary"
        >
          <v-col cols="10" offset="1" class="justify-self-center">
            <div class="text-center mb-2">
              <v-icon color="grey darken-3">mdi-key-variant</v-icon>
            </div>
            <div class="text-center">
              <h1 class="title d-flex justify-center mb-5">
                <v-icon color="primary" class="mr-2"
                  >mdi-information-outline</v-icon
                >
                {{ disabledTitle }}
              </h1>
              <span v-html="disabledText"></span>
            </div>
          </v-col>
        </v-overlay>
        <v-row>
          <v-col cols="12" md="6">
            <v-card flat>
              <v-card-title class="subtitle-2 text-uppercase mb-1 pb-0">
                {{ $t("machines.notifications.settings.recipients") }}
              </v-card-title>
              <v-card-text>
                <div>
                  <v-alert
                    v-if="selectionError"
                    type="warning"
                    class="mt-4 mb-4"
                    elevation="2"
                    border="left"
                    colored-border
                    dense
                  >
                    {{
                      $t("machines.notifications.settings.maxRecipientsHint")
                    }}
                  </v-alert>
                </div>
                <v-select
                  v-model="selectedEmails"
                  :disabled="!editNotification"
                  :loading="loading"
                  :menu-props="{ maxHeight: '400' }"
                  prepend-icon="mdi-email"
                  multiple
                  :hint="$t('machines.notifications.settings.selectEmails')"
                  persistent-hint
                  :items="users"
                  item-text="email"
                  item-value="email"
                ></v-select>
                <p v-if="selectedEmails.length === 0" class="caption mt-3">
                  {{ $t("machines.notifications.settings.norecipientsAdded") }}
                </p>
              </v-card-text>
              <v-card-actions>
                <v-tooltip top v-if="canEditItem">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      <v-btn
                        class="pa-4 grab-send-notifications-btn"
                        :disabled="
                          isSendingNotification ||
                            selectedEmails.length === 0 ||
                            editNotification
                        "
                        :loading="isSendingNotification"
                        @click.stop="onSendNotificationClick"
                      >
                        <v-icon left>mdi-email-send-outline</v-icon>
                        {{
                          $t("machines.notifications.settings.actions.send")
                        }}</v-btn
                      >
                    </span>
                  </template>
                  <span>{{
                    $t("machines.notifications.settings.sendHint")
                  }}</span>
                </v-tooltip>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card flat>
              <v-card-title class="subtitle-2 text-uppercase mb-1 pb-0">
                {{ $t("machines.notifications.settings.threshold") }}
              </v-card-title>
              <v-card-text>
                <v-select
                  :items="maintenanceThresholds"
                  :disabled="!editNotification"
                  v-model="threshold"
                  :hint="thresholdHint"
                  persistent-hint
                ></v-select>
                <v-select
                  v-if="hasDayThresholds"
                  :items="dayThresholds"
                  :disabled="!editNotification"
                  v-model="thresholdYearly"
                  :hint="thresholdDayHint"
                  persistent-hint
                ></v-select>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-card-text>
    <v-card-actions v-if="editNotification">
      <v-spacer></v-spacer>
      <v-btn
        :loading="isUpdatingNotification"
        :disabled="
          isUpdatingNotification ||
            selectedEmails.length === 0 ||
            selectedEmails.length > 5
        "
        @click.stop="onSaveClick"
        >{{ $t("common.actions.save") }}</v-btn
      >
      <v-btn
        :loading="isUpdatingNotification"
        :disabled="isUpdatingNotification"
        @click.stop="onCancelClick"
        >{{ $t("common.actions.cancel") }}</v-btn
      >
    </v-card-actions>

    <ConfirmRemoveDialog
      itemName="notification settings"
      confirmRemoveEvent="confirmEvent"
      :show="showConfirmRemove"
      @close="showConfirmRemove = false"
      v-on:confirmEvent="onRemoveClick"
    />

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          {{ $t("common.actions.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import ValidationRules from "@/utils/validationrules"
import ConfirmRemoveDialog from "@/dialogs/ConfirmRemoveDialog"

let validationRules = null

export default {
  name: "NotificationSettings",
  props: [
    "serialNo",
    "disabled",
    "disabledTitle",
    "disabledText",
    "notification",
    "notificationType", // "maintenance", "lease-info"
    "canEditItem",
    "canRemoveItem",
    "saveAction",
    "cancelAction",
    "removeAction"
  ],
  components: { ConfirmRemoveDialog },
  data() {
    return {
      validationRules: validationRules,

      threshold: 10,
      thresholdYearly: 30,

      isSendingNotification: false,

      isUpdatingNotification: false,
      editNotification: false,
      showConfirmRemove: false,

      selectedEmails: [],
      selectionError: null,

      snackbar: false,
      snackbarText: ""
    }
  },
  mounted() {
    this.assignPropsToData()
    this.fetchUsers()
  },
  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },
  computed: {
    maintenanceThresholds() {
      return this.$store.getters.maintenanceThresholds
    },
    dayThresholds() {
      return [
        {
          text: `${this.$tc("common.units.day", 7)}`,
          value: 7
        },
        {
          text: `${this.$tc("common.units.day", 14)}`,
          value: 14
        },
        {
          text: `${this.$tc("common.units.day", 30)}`,
          value: 30
        }
      ]
    },
    defaultThresholdYearly() {
      return this.isMaintenanceType ? 30 : 7
    },
    hasDayThresholds() {
      return true
    },
    isMaintenanceType() {
      return this.notificationType === "maintenance"
    },
    thresholdHint() {
      return this.isMaintenanceType
        ? this.$t(
            "machines.notifications.settings.engineHoursBeforeNextService"
          )
        : this.$t(
            "machines.notifications.settings.engineHoursBeforeLeaseExpires"
          )
    },
    thresholdDayHint() {
      return this.isMaintenanceType
        ? this.$t("machines.notifications.settings.daysBeforeNextYearlyService")
        : this.$t("machines.notifications.settings.daysBeforeLeaseExpiration")
    },
    tenantUid() {
      return this.$route.params.tenantUid
    },
    users() {
      return this.$store.getters.users
    },
    loading() {
      return this.$store.getters.loading
    }
  },
  watch: {
    notification(newVal) {
      if (newVal !== undefined && newVal !== null) {
        this.selectedEmails = newVal.recipients
        this.threshold = newVal.threshold

        if (this.notificationType === "maintenance") {
          this.thresholdYearly = newVal.thresholdYearly
        } else {
          this.thresholdYearly = newVal.thresholdDays
        }
      } else {
        this.selectedEmails = []
        this.threshold = 10
        this.thresholdYearly = this.defaultThresholdYearly
      }
    },
    selectedEmails(newVal) {
      if (newVal.length > 5) {
        this.selectionError = "At most 5 email addresses can be added"
      } else {
        this.selectionError = null
      }
    }
  },
  methods: {
    assignPropsToData() {
      if (this.notification !== undefined && this.notification !== null) {
        this.selectedEmails = this.notification.recipients
        this.threshold = this.notification.threshold
        if (this.notificationType === "maintenance") {
          this.thresholdYearly = this.notification.thresholdYearly
        } else {
          this.thresholdYearly = this.notification.thresholdDays
        }
      } else {
        this.selectedEmails = []
        this.threshold = 10
        this.thresholdYearly = this.defaultThresholdYearly
      }
    },
    fetchUsers() {
      if (this.$store.getters.users.length == 0) {
        // Fetch users, since list is empty.
        // Could be OK that it's empty but just to ensure users are not missing.
        this.$store.dispatch("fetchUsers", this.tenantUid)
      }
    },
    onThresholdChange(thresholdValue) {
      this.$emit("thresholdChange", thresholdValue)
    },
    onSaveClick() {
      const params = {
        recipients: this.selectedEmails,
        threshold: this.threshold
      }
      if (this.isMaintenanceType) {
        params.thresholdYearly = this.thresholdYearly
      } else {
        params.thresholdDays = this.thresholdYearly
      }

      this.$emit("save", params)
      this.editNotification = false
    },
    onCancelClick() {
      this.assignPropsToData()
      this.$emit("cancel", {})
      this.editNotification = false
    },
    onRemoveClick() {
      this.isUpdatingNotification = true
      this.showConfirmRemove = false
      this.$emit("remove", {})
      this.isUpdatingNotification = false
    },
    onSendNotificationClick() {
      var dialog = {
        title: this.$t("machines.notifications.settings.actions.send"),
        message: this.$tc(
          "machines.notifications.settings.dialogs.sendNotification.confirm",
          this.selectedEmails.length
        ),
        icon: "mdi-help-circle",
        buttons: [
          {
            label: "Yes",
            action: () => {
              this.isSendingNotification = true

              this.$store
                .dispatch("sendEmailNotification", {
                  serialNo: this.serialNo,
                  type: this.notificationType
                })
                .then(() => {
                  this.snackbar = true
                  this.snackbarText = this.$tc(
                    "machines.notifications.settings.dialogs.sendNotification.successMessage",
                    this.selectedEmails.length
                  )
                })
                .catch(() => {
                  // Error already displayed by sendEmailNotification.
                })
                .finally(() => {
                  this.isSendingNotification = false
                })
            }
          },
          {
            label: "No",
            action: () => {
              return false
            }
          }
        ]
      }

      this.$store.dispatch("setGenericDialog", dialog)
    }
  }
}
</script>

<style></style>
