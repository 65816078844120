<template>
  <v-card class="pa-0 pb-4 ma-4" color="white">
    <v-card-title>
      {{ productName }}
    </v-card-title>
    <v-card-subtitle>
      {{ serialRange }}
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <!-- DEFINE TABS -->
          <v-tabs
            v-model="tab"
            background-color="white"
            color="black accent-4"
            left
          >
            <v-tabs-slider></v-tabs-slider>
            <!-- MANUALS TAB -->
            <v-tab href="#manuals">
              <v-icon class="mr-2" small>mdi-book-open-variant</v-icon>
              {{ $t("documents.manuals.title") }}
            </v-tab>
            <!-- SPARE PARTS TAB -->
            <v-tab href="#spareParts" v-if="canReadSpareParts">
              <v-icon class="mr-2" small>mdi-cog-outline</v-icon>
              {{ $t("documents.spareParts.title") }}
            </v-tab>
            <!--  TROUBLE SHOOTING TAB -->
            <v-tab href="#troubleShooting">
              <v-icon class="mr-2" small>mdi-toolbox</v-icon>
              {{ $t("documents.troubleshooting.title") }}
            </v-tab>
            <!-- TECHNICAL DATA TAB -->
            <v-tab href="#technicaldata">
              <v-icon class="mr-2" small>mdi-information-outline</v-icon>
              {{ $t("documents.technicalData.title") }}
            </v-tab>
            <!-- DIAGRAMS TAB -->
            <v-tab href="#diagrams" v-if="isService || isAdmin">
              <v-icon class="mr-2" small>mdi-tournament</v-icon>
              {{ $t("documents.diagrams.title") }}
            </v-tab>
            <!-- CE TAB -->
            <v-tab href="#CE">
              <v-icon class="mr-2" small>mdi-atom</v-icon>
              {{ $t("documents.ce.title") }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <!-- MANUALS -->
      <v-tabs-items v-model="tab">
        <v-tab-item value="manuals">
          <v-container fluid px-md-6>
            <v-row class="d-flex align-end">
              <v-col xs="auto">
                <v-text-field
                  v-model="search"
                  prepend-icon="mdi-magnify"
                  :label="$t('common.filters.filter')"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex align-end">
              <v-col xs="auto" class="mr-0">
                <v-data-table
                  v-if="manuals"
                  :headers="headers"
                  :items="manuals"
                  :search="search"
                  :custom-filter="searchFilter"
                  :loading="isDownloadingFile"
                  hide-default-footer
                  disable-pagination
                  sort-by="name"
                  sort-asc
                >
                  <template v-slot:[`item.icon`]="{}">
                    <v-icon> mdi-book-open-variant </v-icon>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <span @click="rowClickHandler(item)">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon small v-on="on">mdi-download</v-icon>
                        </template>
                        {{ $t("machines.manuals.actions.downloadFile") }}
                      </v-tooltip>
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- SPARE PARTS -->
        <v-tab-item value="spareParts" v-if="canReadSpareParts">
          <v-container
            v-if="spareParts === undefined || !spareParts.length"
            fluid
          >
            <v-alert type="info">
              {{ $t("documents.spareParts.unavailable") }}
            </v-alert>
          </v-container>
          <v-container v-else fluid px-md-6>
            <v-row class="d-flex align-end">
              <v-col xs="auto" class="mr-0">
                <v-text-field
                  v-model="search"
                  prepend-icon="mdi-magnify"
                  :label="$t('common.filters.filter')"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="d-flex align-end">
              <v-col xs="auto" class="mr-0">
                <v-data-table
                  v-if="spareParts"
                  :headers="headers"
                  :items="spareParts"
                  :search="search"
                  :custom-filter="searchFilter"
                  :loading="isDownloadingFile"
                  sort-by="name"
                  sort-asc
                >
                  <template v-slot:[`item.icon`]="{}">
                    <v-icon>mdi-book-open-variant</v-icon>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <span @click="rowClickHandler(item)">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon small v-on="on">mdi-download</v-icon>
                        </template>
                        {{ $t("machines.manuals.actions.downloadFile") }}
                      </v-tooltip>
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- TROUBLESHOOTING -->
        <v-tab-item value="troubleShooting">
          <v-container
            v-if="
              (troubleShootings === undefined && !this.showInfo) ||
                (!troubleShootings.length && !this.showInfo)
            "
            fluid
          >
            <v-alert type="info">
              {{ $t("documents.troubleshooting.unavailable") }}
            </v-alert>
          </v-container>
          <v-container v-else-if="this.showInfo" fluid>
            <v-alert type="info">
              {{ $t("documents.troubleshooting.includedInManual") }}
            </v-alert>
          </v-container>
          <v-container v-else fluid px-md-6>
            <v-row class="d-flex align-end">
              <v-col xs="auto" class="mr-0">
                <v-text-field
                  v-model="search"
                  prepend-icon="mdi-magnify"
                  :label="$t('common.filters.filter')"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex align-end">
              <v-col xs="auto" class="mr-0">
                <v-data-table
                  v-if="troubleShootings"
                  :headers="headers"
                  :items="troubleShootings"
                  :search="search"
                  :custom-filter="searchFilter"
                  :loading="isDownloadingFile"
                  sort-by="name"
                  sort-asc
                >
                  <template v-slot:[`item.icon`]="{}">
                    <v-icon>mdi-book-open-variant </v-icon>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <span @click="rowClickHandler(item)">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon small v-on="on">mdi-download</v-icon>
                        </template>
                        {{ $t("machines.manuals.actions.downloadFile") }}
                      </v-tooltip>
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- TECHNICAL DATA -->
        <v-tab-item value="technicaldata">
          <v-container
            v-if="
              (technicalData === undefined && !this.showInfo) ||
                (!technicalData.length && !this.showInfo)
            "
            fluid
          >
            <v-alert type="info">
              {{ $t("documents.technicalData.unavailable") }}
            </v-alert>
          </v-container>
          <v-container v-else-if="this.showInfo" fluid>
            <v-alert type="info">
              {{ $t("documents.technicalData.includedInManual") }}
            </v-alert>
          </v-container>
          <v-container v-else fluid px-md-6>
            <v-row class="d-flex align-end">
              <v-col xs="auto" class="mr-0">
                <v-text-field
                  v-model="search"
                  prepend-icon="mdi-magnify"
                  :label="$t('common.filters.filter')"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="d-flex align-end">
              <v-col xs="auto" class="mr-0">
                <v-data-table
                  v-if="technicalData"
                  :headers="headers"
                  :items="technicalData"
                  :search="search"
                  :custom-filter="searchFilter"
                  :loading="isDownloadingFile"
                  sort-by="name"
                  sort-asc
                >
                  <template v-slot:[`item.icon`]="{}">
                    <v-icon>mdi-book-open-variant</v-icon>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <span @click="rowClickHandler(item)">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon small v-on="on">mdi-download</v-icon>
                        </template>
                        {{ $t("machines.manuals.actions.downloadFile") }}
                      </v-tooltip>
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- DIAGRAMS -->
        <v-tab-item value="diagrams">
          <v-container v-if="diagrams === undefined || !diagrams.length" fluid>
            <v-alert type="info">
              {{ $t("documents.diagrams.unavailable") }}
            </v-alert>
          </v-container>
          <v-container v-else fluid px-md-6>
            <v-row class="d-flex align-end">
              <v-col xs="auto" class="mr-0">
                <v-text-field
                  v-model="search"
                  prepend-icon="mdi-magnify"
                  :label="$t('common.filters.filter')"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="d-flex align-end">
              <v-col xs="auto" class="mr-0">
                <v-data-table
                  v-if="diagrams"
                  :headers="headers"
                  :items="diagrams"
                  :search="search"
                  :custom-filter="searchFilter"
                  :loading="isDownloadingFile"
                  sort-by="name"
                  sort-asc
                >
                  <template v-slot:[`item.icon`]="{}">
                    <v-icon>mdi-book-open-variant</v-icon>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <span @click="rowClickHandler(item)">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon small v-on="on">mdi-download</v-icon>
                        </template>
                        {{ $t("machines.manuals.actions.downloadFile") }}
                      </v-tooltip>
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>

        <!-- CE -->
        <v-tab-item value="CE">
          <v-container v-if="CE === undefined || !CE.length" fluid>
            <v-alert type="info">
              {{ $t("documents.ce.unavailable") }}
            </v-alert>
          </v-container>
          <v-container v-else fluid px-md-6>
            <v-row class="d-flex align-end">
              <v-col xs="auto" class="mr-0">
                <v-text-field
                  v-model="search"
                  prepend-icon="mdi-magnify"
                  :label="$t('common.filters.filter')"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="d-flex align-end">
              <v-col xs="auto" class="mr-0">
                <v-data-table
                  v-if="CE"
                  :headers="headers"
                  :items="CE"
                  :search="search"
                  :custom-filter="searchFilter"
                  :loading="isDownloadingFile"
                  sort-by="name"
                  sort-asc
                >
                  <template v-slot:[`item.icon`]="{}">
                    <v-icon>mdi-book-open-variant</v-icon>
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <span @click="rowClickHandler(item)">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon small v-on="on">mdi-download</v-icon>
                        </template>
                        {{ $t("machines.manuals.actions.downloadFile") }}
                      </v-tooltip>
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import fetchClient from "@/utils/fetchClient"
import { mapActions } from "vuex"
import countryList from "country-list"

export default {
  data() {
    return {
      tab: "manuals",
      search: "",
      manuals: [],
      spareParts: [],
      troubleShootings: [],
      technicalData: [],
      diagrams: [],
      CE: [],
      downloadingFiles: 0,
      filesLoading: [],
      url: ""
    }
  },

  computed: {
    isUser() {
      return this.$store.getters.isUser
    },
    isService() {
      return this.$store.getters.isService
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    canReadSpareParts() {
      return this.$store.getters.canReadSpareParts
    },
    headers() {
      return [
        {
          text: "",
          align: "right",
          sortable: false,
          value: "icon",
          width: 20
        },
        {
          text: this.$t("machines.manuals.dataTable.headers.fileName"),
          align: "left",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("common.dataTable.headers.actions"),
          align: "right",
          sortable: false,
          value: "action"
        }
      ]
    },
    showInfo() {
      return this.$route.query.includeTroubleshooting === "true"
    },
    productName() {
      let name = this.$route.query.productName
      if (this.revision.length > 3) {
        // Magic 3 since the revision can be 'rev', which makes no sense to include
        name = `${name} / ${this.revision}`
      }
      return name
    },
    serialRange() {
      return this.$route.query.serialRange
    },
    revision() {
      return this.$route.query.revision
    },
    productId() {
      return this.$route.query.productId
    },
    isDownloadingFile() {
      return this.downloadingFiles > 0
    }
  },

  async created() {
    this.fetch(this.productId, this.revision, "Manuals")
    this.fetch(this.productId, this.revision, "Spare_parts")
    this.fetch(this.productId, this.revision, "Troubleshooting")
    this.fetch(this.productId, this.revision, "Technical_data")
    this.fetch(this.productId, this.revision, "Diagrams")
    this.fetch(this.productId, this.revision, "CE")
  },

  methods: {
    ...mapActions(["setError"]),

    // fetch
    async fetch(productId, revision, type) {
      // Remove spaces
      revision = revision.replace(/\s/g, "")

      this.url = `/api/v1/documents/${productId}/${revision}/${type}`

      this.loading = true

      try {
        const files = await fetchClient(this.$i18n).getDataOrThrow(this.url)
        let countryCode = ""
        let obj = files
        for (var k in obj) {
          if (obj[k] instanceof Object) {
            files[k].name = files[k].filename
            if (type == "Spare_parts") {
              continue
            } else if (type == "Manuals" || type == "Troubleshooting") {
              countryCode = obj[k].name.substr(obj[k].name.length - 6, 2)
              files[k].name = countryList.getName(countryCode)
            } else {
              files[k].name = files[k].name + "  (Multilanguage)"
            }
          }
        }

        if (type == "Manuals") {
          this.manuals = files
        } else if (type == "Troubleshooting") {
          this.troubleShootings = files
        } else if (type == "Spare_parts") {
          this.spareParts = files
        } else if (type == "Technical_data") {
          this.technicalData = files
        } else if (type == "Diagrams") {
          this.diagrams = files
        } else {
          this.CE = files
        }
      } catch (error) {
        this.setError(error)
      }

      this.loading = false
    },

    async rowClickHandler(clickedItem) {
      if (!clickedItem.locked) {
        this.downloadingFiles += 1
        clickedItem.locked = true

        let errorData = null
        const downloadPath =
          "/api/v1/downloads/" + clickedItem.path + "/" + clickedItem.filename

        try {
          const response = await fetchClient(this.$i18n).getResponse(
            "GET",
            downloadPath
          )

          if (response.status === 200) {
            const responseData = await response.arrayBuffer()
            const blob = new Blob([responseData], {
              type: response.headers.get("content-type")
            })

            let link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)
            link.download = clickedItem.filename
            link.click()
          } else {
            errorData = await fetchClient(this.$i18n).getError(response)
          }
        } catch (error) {
          errorData = {
            message: this.$t("machines.manuals.messages.failedToDownload", {
              file: downloadPath
            }),
            causedBy: error.toString(),
            details: error.message
          }
        } finally {
          clickedItem.locked = false
          this.downloadingFiles -= 1

          if (errorData != null) {
            errorData.requestMethod = "GET"
            errorData.requestUri = downloadPath

            const err = {
              response: {
                data: errorData
              }
            }
            this.$store.dispatch("setError", err)
          }
        }
      }
    },
    searchFilter(value, search, item) {
      return item.name.toLowerCase().includes(search.toLowerCase())
    }
  }
}
</script>
