import { store } from "../../store"

// The cookie should include the domain so that it applies to subdomains as well.
const domainName = process.env.VUE_APP_DOMAIN_NAME || "localhost"

function setCookie(cookieName, cookieValue, expireTime, domain, path) {
  const expDate = new Date()
  // Set number of milliseconds since epoch
  expDate.setTime(expireTime * 1000)
  const expires = `expires=${expDate.toGMTString()}`

  const cookie = `${cookieName}=${cookieValue};${expires};Secure;Domain=${domain};Path=${path}`
  document.cookie = cookie
}

function getCookie(cname) {
  let name = cname + "="
  let ca = document.cookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

function deleteCookie(cookieName, path) {
  const deleteCookie = `${cookieName}=; Path=${path}; Expires=Thu, 01 Jan 1970 00:00:01 GMT`
  document.cookie = deleteCookie
  document.cookie = `${deleteCookie}; Domain=${domainName}`
}

export function removeTokenCookies() {
  deleteCookie("tkn", "/")
  deleteCookie("access_tkn", store.getters.webshopStartPage)
  deleteCookie("refresh_tkn", store.getters.webshopStartPage)
  deleteCookie("access_tkn", "/")
  deleteCookie("refresh_tkn", "/")
}

export function saveTokenCookies(tokens, expireTime) {
  // ID token contains role, permisionss, web shop access etc,
  // and gets stored in a cookie for the root path (/)
  setCookie("tkn", tokens.idToken, expireTime, domainName, "/")
  setCookie("access_tkn", tokens.accessToken, expireTime, domainName, "/")
  setCookie("refresh_tkn", tokens.refreshToken, expireTime, domainName, "/")
}

export function saveWebshopCookie(coookieName, data, expireTime) {
  // The cookie should include the doman so that it applies to subdomains as well.
  const domainName = process.env.VUE_APP_DOMAIN_NAME || "localhost"

  /* The Access and Refresh tokens get stored with a path used by the web shop: /portal */
  setCookie(
    coookieName,
    data,
    expireTime,
    domainName,
    store.getters.webshopStartPage
  )
}

export function removeWebshopCookies() {
  deleteCookie("bc.ws.idToken", store.getters.webshopStartPage)
  deleteCookie("bc.ws.userData", store.getters.webshopStartPage)
  deleteCookie("bc.ws.LastAuthUser", store.getters.webshopStartPage)
  deleteCookie("bc.ws.accessToken", store.getters.webshopStartPage)
  deleteCookie("bc.ws.refreshToken", store.getters.webshopStartPage)
  deleteCookie("bc.ws.clockDrift", store.getters.webshopStartPage)
}

export function loadLanguageCookie() {
  var cookie = getCookie("language", domainName ?? "/")
  return cookie
}

export function saveLanguageCookie(language) {
  setCookie("language", language, new Date(), domainName, "/")
}
