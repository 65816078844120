<template>
  <v-card flat outlined>
    <v-card-title>
      {{ $t("machines.maintenance.service.schedule.title") }}
      <v-menu>
        <template v-slot:activator="{ on: menu }">
          <v-btn icon v-on="menu" class="grab-service-schedule-menu">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="exportCsv">
            <v-list-item-title class="body-2">
              <v-icon small>mdi-file-delimited</v-icon>
              {{ $t("common.actions.exportAsCSV") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        icon
        class="grab-service-schedule-menu"
        v-if="machine.unprovisioned && userHasServiceOrAdminRole"
        @click="addService"
      >
        <v-icon>mdi-plus-circle</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle>
      <p>
        {{ $t("machines.maintenance.service.schedule.description") }}
      </p>
    </v-card-subtitle>
    <v-card-text class="black--text">
      <v-data-table
        class="service-schedule"
        :headers="headers"
        :items="schedule"
        :loading="loading"
        disable-pagination
        disable-sort
        hide-default-footer
      >
        <template v-slot:[`item.status`]="{ value }">
          <v-chip small dark :color="getStatusColor(value)">
            {{ value.toUpperCase() }}
          </v-chip>
        </template>

        <template v-slot:[`item.engineHours`]="{ item }">
          <template v-if="item.status === 'completed'">
            {{ item.engineHours }}
          </template>
          <template v-else>-</template>
        </template>

        <template v-slot:[`item.serviceProtocol`]="{ item }">
          <v-tooltip top v-if="item.serviceProtocolUid?.length > 0">
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-progress-circular
                  v-if="item.loading"
                  indeterminate
                  :size="20"
                  :width="2"
                />
                <v-icon v-else @click="downloadMaintenanceLog(item)"
                  >mdi-download
                </v-icon>
              </span>
            </template>
            <span>{{
              $t(
                "machines.maintenance.service.schedule.downloadServiceProtocol"
              )
            }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.comment`]="{ value }">
          <em v-if="value === null">-</em>
          <v-tooltip v-else-if="value !== undefined" top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ value | truncateTextFilter(30) }}
              </span>
            </template>
            <span class="service-comment">{{ value }}</span>
          </v-tooltip>
          <template v-else>-</template>
        </template>

        <template v-slot:[`item.serviceDate`]="{ item, value }">
          <v-tooltip v-if="item.status === 'completed'" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="userHasServiceOrAdminRole && !machine.unprovisioned"
                small
                left
                @click="editServiceDate(item)"
              >
                mdi-pencil
              </v-icon>

              <span v-bind="attrs" v-on="on">
                <span>{{ value }}</span>
              </span>
            </template>

            <span
              >{{ $t("machines.maintenance.service.schedule.received") }}:
              {{ item.receiveDate }}</span
            >
          </v-tooltip>

          <template v-else>-</template>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-menu left>
            <template v-slot:activator="{ on: menu }">
              <v-btn icon color="primary" v-on="menu">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="canEditItem" @click="editItem(item)">
                <v-list-item-title class="body-2">
                  <v-icon small>mdi-pencil</v-icon>
                  {{ $t("common.actions.edit") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card-text>

    <EditServiceDateDialog ref="editDateDialog" />

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          {{ $t("common.actions.close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <EditServiceDialog
      :service="serviceItem"
      :show="showEditDialog"
      @saveEdit="onEditSave"
      @cancelEdit="onEditCancel"
    />

    <GuidedServiceAdd
      :machine="machine"
      :show="showAddServiceDialog"
      @cancelAdd="onAddServiceCancel"
      @saveAdd="onAddServiceSave"
    />
  </v-card>
</template>

<script>
import EditServiceDialog from "@/dialogs/EditServiceDialog"
import EditServiceDateDialog from "@/dialogs/EditServiceDateDialog"
import GuidedServiceAdd from "@/dialogs/GuidedServiceAdd"
import Exporter from "@/utils/csvexport"
import { fetchServicePortalClient } from "../utils/fetchClient"

export default {
  props: ["machine"],
  components: { EditServiceDateDialog, GuidedServiceAdd, EditServiceDialog },

  data: () => ({
    serviceItem: {},
    snackbar: false,
    snackbarText: null,
    showAddServiceDialog: false,
    showEditDialog: false
  }),

  computed: {
    headers() {
      const headerArr = [
        {
          text: this.$t(
            "machines.maintenance.service.schedule.dataTable.headers.status"
          ),
          value: "status",
          width: "13%"
        },
        {
          text: this.$t(
            "machines.maintenance.service.schedule.dataTable.headers.service"
          ),
          value: "name",
          width: "13%"
        },
        {
          text: this.$t(
            "machines.maintenance.service.schedule.dataTable.headers.loggedHours"
          ),
          value: "engineHours",
          width: "13%"
        },
        {
          text: this.$t(
            "machines.maintenance.service.schedule.dataTable.headers.comment"
          ),
          value: "comment",
          width: "13%"
        },
        {
          text: this.$t(
            "machines.maintenance.service.schedule.dataTable.headers.serviceDate"
          ),
          value: "serviceDate",
          width: "13%"
        }
      ]

      if (this.userHasServiceOrAdminRole) {
        if (this.machine.unprovisioned !== false) {
          headerArr.push({
            text: this.$t("common.dataTable.headers.actions"),
            value: "action",
            sortable: false
          })
        }

        headerArr.push({
          text: this.$t(
            "machines.maintenance.service.schedule.dataTable.headers.serviceProtocol"
          ),
          value: "serviceProtocol",
          sortable: false,
          width: "1%"
        })
      }

      return headerArr
    },
    loading() {
      return this.$store.getters.loadingMaintenance
    },
    maintenance() {
      if (
        this.$store.getters.maintenance !== undefined &&
        this.$store.getters.maintenance !== null
      ) {
        return this.$store.getters.maintenance
      }

      return { upcoming: [], history: [] }
    },
    schedule() {
      const history = this.maintenance.history.map(service => {
        const engineHours = Math.floor(service.engineSeconds / 3600)

        let comment = null
        if (service.comment !== null) {
          comment = service.comment.trim()
        }

        const receiveDate = new Date(service.receiveTime)
        let serviceDate = receiveDate

        if (service.serviceDate !== null) {
          const date = new Date(service.serviceDate)
          serviceDate = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate()
          )
        }

        return {
          name: `${this.$t("machines.service")} ${service.number}`,
          status: "completed",
          engineHours: `${engineHours} h`,
          date: receiveDate.toLocaleDateString(),
          scheduled: this.getScheduledString(service.scheduled),
          serviceDate: serviceDate.toLocaleDateString(),
          receiveDate: receiveDate.toLocaleDateString(),
          original: service,
          comment,
          engineHoursEdit: `${engineHours}`,
          engineHoursOld: `${engineHours}`,
          stepEdit: service.number,
          oldStep: service.number,
          serviceDateEdit: service.serviceDate?.split("T")[0],
          serviceProtocolUid: service.serviceProtocolUid
        }
      })

      if (this.machine.unprovisioned === false) {
        const upcoming = this.maintenance.upcoming.map(service => {
          return {
            name: `${this.$t("machines.service")} ${service.number}`,
            status: service.status,
            scheduled: this.getScheduledString(service)
          }
        })

        return [...history, ...upcoming].reverse()
      } else {
        return [...history].reverse()
      }
    },
    userHasServiceOrAdminRole() {
      return this.$store.getters.isService || this.$store.getters.isAdmin
    },
    canEditItem() {
      return this.$store.getters.canEditMaintenance
    }
  },

  methods: {
    getStatusColor(status) {
      switch (status) {
        case "upcoming":
          return "grey"
        case "pending":
          return "orange"
        case "completed":
          return "green"
        case "overdue":
          return "red"
      }

      return null
    },

    getStatusText(item) {
      return item.status.toUpperCase()
    },

    getScheduledString(scheduled) {
      const parts = []
      if (scheduled.hours !== null) {
        parts.push(`${scheduled.hours} h`)
      }
      if (scheduled.date !== null) {
        const date = new Date(scheduled.date)
        parts.push(date.toLocaleDateString())
      }

      return parts.join(" or ")
    },

    exportCsv() {
      const rows = Exporter(this.$i18n).exportServiceSchedule(
        this.machine,
        this.schedule
      )
      this.snackbarText = this.$t("common.messages.recordsExported", {
        number: rows
      })
      this.snackbar = true
    },

    async editServiceDate(service) {
      let currentDate = service.original.receiveTime
      if (service.original.serviceDate !== null) {
        currentDate = service.original.serviceDate
      }

      const updated = await this.$refs.editDateDialog.open(
        this.machine,
        service.original.engineSeconds,
        currentDate
      )

      if (updated) {
        this.$emit("update")
      }
    },
    addService() {
      this.showAddServiceDialog = true
    },
    onAddServiceCancel() {
      this.showAddServiceDialog = false
      this.refreshItems()
    },
    onAddServiceSave() {
      this.showAddServiceDialog = false
      this.refreshItems()
      this.snackbarText = this.$t("machines.maintenance.service.add")
      this.snackbar = true
    },
    async refreshItems() {
      const params = {
        tenantUid: this.$route.params.tenantUid,
        serialNo: this.$route.params.serialNo
      }

      this.$store.dispatch("fetchMaintenance", params)
    },
    onEditSave() {
      this.showEditDialog = false
      this.refreshItems()
      this.snackbarText = this.$t("machines.maintenance.service.update")
      this.snackbar = true
    },
    onEditCancel() {
      this.showEditDialog = false
      this.refreshItems()
    },
    editItem(item) {
      this.serviceItem = Object.assign({}, item)
      this.showEditDialog = true
    },
    downloadMaintenanceLog(item) {
      try {
        item.loading = true
        const url = `/api/v1/tenants/${this.machine.tenantUid}/machines/${this.machine.serialNo}/maintenance/service-protocols/${item.serviceProtocolUid}/file-generation`
        fetchServicePortalClient()
          .getDataOrThrow(url)
          .then(response => {
            try {
              const binaryData = atob(response)

              const byteArray = new Uint8Array(binaryData.length)
              for (let i = 0; i < binaryData.length; i++) {
                byteArray[i] = binaryData.charCodeAt(i)
              }

              const pdfBlob = new Blob([byteArray], { type: "application/pdf" })
              const date = new Date().toLocaleString("en-GB").substring(0, 10)

              const filename = `Brokk-Service_Protocol-${this.machine.serialNo}-${date}.pdf`

              let link = document.createElement("a")
              link.href = window.URL.createObjectURL(pdfBlob)
              link.download = filename
              link.click()
            } finally {
              item.loading = false
            }
          })
      } catch (error) {
        this.setError(error)
        item.loading = false
      }
    }
  }
}
</script>

<style scoped>
.service-comment {
  white-space: pre-wrap;
}
</style>

<style>
.service-schedule .v-data-table__wrapper tr {
  cursor: auto;
}
</style>
