<template>
  <v-container class="login-background">
    <v-container
      fluid
      class="d-flex justify-center align-center login-form-container"
    >
      <v-card
        elevation="24"
        outlined
        raised
        :style="minWidth"
        class="rounded-lg px-md-4"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <img style="max-height:32px;" :src="$store.getters.logoBlack" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mt-md-4 mb-md-10 pb-md-4">
              <p class="text-center mb-0 signInText">my brokk</p>
            </v-col>
          </v-row>
          <v-form ref="loginForm" autocomplete="off" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12" class="px-2 pb-2">
                  <v-text-field
                    outlined
                    dense
                    hide-details="auto"
                    autofocus
                    v-model="email"
                    prepend-icon="mdi-account"
                    required
                    :label="`${$t('login.email')}*`"
                    :rules="[validationRules.email]"
                    v-on:keyup.enter="onLogin"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="px-2 pb-2">
                  <v-text-field
                    outlined
                    dense
                    hide-details="auto"
                    v-model="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    required
                    :label="`${$t('login.password')}*`"
                    :rules="[
                      validationRules.required,
                      validationRules.maxLimit
                    ]"
                    v-on:keyup.enter="onLogin"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="error">
                <v-col cols="12">
                  <app-alert
                    @dismissed="dismissAlert"
                    :errItem="error"
                  ></app-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pl-10 pr-2">
                  <v-btn
                    :disabled="loading"
                    rounded-lg
                    class="signInBtnText"
                    block
                    @click.stop="onLogin"
                    >{{ $t("login.signIn") }}</v-btn
                  >
                </v-col>
              </v-row>
              <v-row v-if="loading">
                <v-col cols="12">
                  <v-progress-linear active indeterminate />
                </v-col>
              </v-row>
            </v-container>
          </v-form>

          <v-row class="saira-regular-font footerText">
            <v-col cols="12" class="text-center mt-10 pt-10 pb-0">
              <a :href="brokkSiteLink" target="_blank">
                {{ $t("login.contactUs") }}
              </a>
              &nbsp;|&nbsp;
              <a @click.stop="onForgotPasswordClick" href="/">
                {{ $t("login.forgotPasswordQuestion") }}
              </a>
            </v-col>
          </v-row>
          <v-row class="saira-regular-font footerText">
            <v-col cols="12" class="d-flex justify-center mt-0">
              <LanguageSelect />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
import ValidationRules from "@/utils/validationrules"
import brokkSiteLinks from "@/utils/brokk-site-links"
import LanguageSelect from "@/components/LanguageSelect.vue"
import languages from "@/utils/languages"

let validationRules = null

export default {
  components: { LanguageSelect },
  data() {
    return {
      valid: false,

      email: "",
      password: "",

      validationRules: validationRules
    }
  },
  beforeCreate() {
    const { country } = this.$route.query
    let langCode = languages.getSupportedLanguageCode(country)
    this.$root.setLanguage(langCode)

    validationRules = ValidationRules(this.$i18n)
  },
  created() {
    if (this.$route.query.logout) {
      this.onLogout()
    }
  },
  computed: {
    loading() {
      return this.$store.getters.loading
    },
    error() {
      return this.$store.getters.error
    },
    brokkSiteLink() {
      // Check if we were redirected here from brokk.com
      // so we can create a return link.
      const { country } = this.$route.query
      if (country && brokkSiteLinks.countries.has(country)) {
        return brokkSiteLinks.countries.get(country)
      }

      return brokkSiteLinks.default
    },
    minWidth() {
      var minPercentage = "100%"

      if (this.$vuetify.breakpoint.name === "sm") {
        minPercentage = "75%"
      } else if (this.$vuetify.breakpoint.name === "md") {
        minPercentage = "30%"
      } else if (this.$vuetify.breakpoint.name === "lg") {
        minPercentage = "25%"
      } else if (this.$vuetify.breakpoint.name === "xl") {
        minPercentage = "20%"
      }
      return `width:${minPercentage}`
    }
  },
  watch: {
    email() {
      this.dismissAlert()
    },
    password() {
      this.dismissAlert()
    }
  },
  methods: {
    async onLogout() {
      await this.$store.dispatch("logout")
    },
    async onLogin() {
      if (!this.$refs.loginForm.validate()) {
        return
      }
      const email = this.email.trim()
      const password = this.password

      const responseData = await this.$store.dispatch("authenticate", {
        email,
        password
      })

      if (responseData === "NEW_PASSWORD_REQUIRED") {
        this.$router.push(`/verify/${email}/${password}`).catch()
        return
      }
      if (responseData === true) {
        this.$router.push(`${this.$route.query.redirect || "/"}`).catch()
      }
    },
    onForgotPasswordClick(e) {
      e.preventDefault()
      this.$router.push("/forgot_password").catch()
    },
    dismissAlert() {
      this.$store.dispatch("clearError")
    }
  }
}
</script>

<style scoped>
.login-background {
  background-image: url("/brokk-background.jpg");
  background-color: #2d2d2d; /* Used if the image is unavailable */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
  z-index: 1;
  min-width: 100%;
  min-height: 100%;
}

.login-form-container {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  min-height: 100%;
  height: 100%;
}

.login-view__toolbar-title {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2rem;
  margin-top: 7px;
}
.signInText {
  color: #303030;
  font-size: 2em;
  font-weight: 500;
  text-transform: uppercase;
}
.signInBtnText {
  color: #303030;
  background-color: #ffcd00 !important;
  font-size: 1.1em;
  font-weight: 600;
  letter-spacing: 0.02em;
}
.footerText {
  color: #303030;
  font-size: 1em;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a:active {
  text-decoration: underline;
}
</style>
