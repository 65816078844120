<template>
  <div>
    <v-card width="220">
      <v-btn icon x-small class="profile-edit-btn" @click="onEditProfile">
        <v-icon>mdi-square-edit-outline</v-icon>
      </v-btn>
      <v-card-text>
        <v-row class="justify-center mb-3">
          <v-col class="d-flex flex-column align-center">
            <p class="overline text-center mb-2">{{ user.roleName }}</p>

            <v-avatar size="80" color="secondary" class="grab-open-profile">
              <v-icon @click="onEditProfile" color="black" x-large
                >mdi-account</v-icon
              >
            </v-avatar>
          </v-col>
        </v-row>
        <div class="text-center">
          <p class="subtitle font-weight-bold mb-2 text-truncate">
            {{ user.email }}
          </p>
          <p class="caption text-center mb-2">
            {{ user.description | optionalTextFilter }}
          </p>
        </div>
      </v-card-text>
      <v-divider />
      <v-list class="py-0">
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          @click="onMenuClicked(item)"
          :class="item.class"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="item.icon">
            <v-icon color="accent">{{ item.icon }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userDetails: {}
    }
  },
  mounted() {
    this.fetchUserDetails()
  },
  methods: {
    onMenuClicked(item) {
      switch (item.callBack) {
        case "changeProfile":
          if (this.$route.path !== "/user_profile") {
            this.$router.push("/user_profile")
          }
          break
        case "changeSettings":
          if (this.$route.path !== "/user_settings") {
            this.$router.push("/user_settings")
          }
          break
        case "changePassword":
          if (this.$route.query.change_password === undefined) {
            this.$router.push("/user_profile?change_password")
          }
          break
        case "signOut":
          this.$store.dispatch("logout")
          break
        default:
          break
      }
    },
    onEditProfile() {
      if (this.$route.path !== "/user_profile") {
        this.$router.push("/user_profile")
      }
    },
    async fetchUserDetails() {
      await this.$store.dispatch("fetchUserDetails", {
        tenantUid: this.loggedInUser.tenantUid,
        uid: this.loggedInUser.uid
      })
      const res = this.$store.getters.userDetails

      if (res !== null) {
        this.userDetails = res
      }
    }
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser
    },
    user() {
      const user = Object.assign({}, this.userDetails)
      user.roleName = this.loggedInUser.roleName
      return user
    },
    menuItems() {
      return [
        {
          title: this.$t("users.settings.menuItems.profile"),
          class: "grab-change-profile",
          callBack: "changeProfile"
        },
        {
          title: this.$t("users.settings.menuItems.settings"),
          class: "grab-change-settings",
          callBack: "changeSettings"
        },
        {
          title: this.$t("login.changePassword"),
          class: "grab-change-password",
          callBack: "changePassword"
        },
        {
          title: this.$t("login.signOut"),
          callBack: "signOut",
          class: "grab-signout",
          icon: "mdi-logout"
        }
      ]
    }
  }
}
</script>

<style scoped>
.title {
  color: #000000;
}
.profile-edit-btn {
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>
