<template>
  <v-container fluid pt-0 pt-md-3>
    <v-card class="mx-auto">
      <v-row no-gutters v-if="machine !== undefined && machine !== null">
        <v-col sm="3" lg="2" cols="12" class="border-right">
          <v-row class="d-flex justify-space-between pa-2">
            <v-col class="d-flex align-self-center justify-start">
              <v-btn @click.stop="goBack" top text>
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
            </v-col>
            <v-col class="d-flex align-self-center justify-end">
              <v-menu>
                <template v-slot:activator="{ on: menu }" v-if="canEditItem">
                  <v-btn
                    top
                    right
                    icon
                    v-on="menu"
                    class="grab-machine-edit-details"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-if="canEditItem" @click="editItem(machine)">
                    <v-list-item-title class="body-2">
                      <v-icon small>mdi-pencil</v-icon>
                      {{ $t("common.actions.edit") }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="canEditItem"
                    @click="transferMachine(machine)"
                  >
                    <v-list-item-title class="body-2">
                      <v-icon small>mdi-transfer</v-icon>
                      {{ $t("common.actions.transfer") }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>

          <v-list class="pt-0">
            <v-list-item>
              <v-list-item-content class="text-center mb-2 mt-2">
                <div class="image-stack">
                  <div class="image-stack-item image-stack-item-top">
                    <v-icon>
                      {{ networkType }}
                    </v-icon>
                    <div class="subtitle-1 font-weight-medium primary--text">
                      {{ networkTypeText }}
                    </div>
                  </div>
                  <img
                    :src="machineImageSrc"
                    :title="machineImageTitle"
                    @error="missingMachineImage"
                    :alt="machine.productName"
                    class="machine-avatar image-stack-item image-stack-item-bottom"
                  />
                </div>
                <v-list-item-title class="title">
                  {{ checkMachineType(machine) }}
                </v-list-item-title>
                <v-list-item-subtitle class="subtitle-1 font-weight-medium">
                  {{ machine.name }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="subtitle-1 font-weight-medium">
                  {{ machine.tenantName }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-list
              class="pa-0 machine-view-list-nav"
              v-if="machine.activeEvents"
            >
              <v-list-item
                v-for="(item, index) in menuItems"
                :key="index"
                router
                dense
                active-class="secondary machine-view-active-item"
                :to="expandedLink(item.link)"
              >
                <v-list-item-icon class="mr-5">
                  <v-badge
                    v-if="item.badge"
                    v-bind="item.badge"
                    bordered
                    overlap
                  >
                    <v-icon color="accent">{{ item.icon }}</v-icon>
                  </v-badge>
                  <v-icon v-else color="accent">{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="machine-view-list-item">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list>
        </v-col>

        <v-col sm="9" md="9" lg="10" cols="12" style="position: relative">
          <router-view :machine="machine"> </router-view>
        </v-col>
      </v-row>
      <v-row no-gutters v-else>
        <v-col sm="3" lg="2" cols="12" class="border-right">
          <v-btn @click.stop="goBack" absolute top text>
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-list>
            <v-list-item>
              <v-list-item-content class="text-center mb-3">
                <img
                  :src="'/products/jpg/default.jpg'"
                  :title="machineImageTitle"
                  :alt="$t('machines.notFound')"
                  class="machine-avatar"
                />
                <v-list-item-title class="title"> Brokk </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $route.params.serialNo }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col>
          <v-card class="ma-3">
            <v-card-title class="secondary py-1">
              {{ $t("machines.messages.fetchingMachineDetails") }}
            </v-card-title>
            <v-card-text class="mt-5">
              <p class="accent--text">
                {{ $t("machines.messages.machineDetailsAreBeeingFetched") }}
              </p>
              <p class="accent--text">
                {{ $t("machines.messages.machineDoesNotLoadInfo") }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn @click.stop="goBack" text>
                {{ $t("common.actions.goBack") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs"> Close </v-btn>
      </template>
    </v-snackbar>

    <EditMachineDialog
      :machine="editedItem"
      :show="showEditDialog"
      @save="onEditSave"
      @cancel="onEditCancel"
    />

    <GuidedMachineTransfer
      :machine="editedItem"
      :show="showTransferDialog"
      @cancel="showTransferDialog = false"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"

import EditMachineDialog from "@/dialogs/EditMachineDialog"
import GuidedMachineTransfer from "@/dialogs/GuidedMachineTransfer"

export default {
  name: "MachineView",
  props: ["tenantUid", "serialNo", "sourceView"] /* passed by the Vue Router */,

  components: {
    EditMachineDialog,
    GuidedMachineTransfer
  },

  data() {
    return {
      machineImageMissing: false,
      editedItem: {},
      showEditDialog: false,
      showTransferDialog: false,
      returnSubView: "map",
      snackbar: false
    }
  },
  async created() {
    if (this.sourceView !== undefined) {
      this.returnSubView = this.sourceView
    }
  },
  methods: {
    //add the "⁺" after the SPP machine names
    checkMachineType() {
      if (
        this.machine.properties !== null &&
        this.machine.properties.tags !== null
      ) {
        if (this.machine.properties.tags.includes("smart_power_plus")) {
          return `${this.machine.productId}⁺`
        }
      }
      return this.machine.productId
    },
    missingMachineImage() {
      this.machineImageMissing = true
    },
    expandedLink(link) {
      return `${link}`
    },
    goBack() {
      this.$router.push({
        path: `/tenants/${this.tenantUid}/${this.returnSubView}`
      })
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.showEditDialog = true
    },
    onEditSave() {
      this.showEditDialog = false
      this.snackbar = true
      this.refreshItems()
    },
    onEditCancel() {
      this.showEditDialog = false
    },
    transferMachine(item) {
      this.editedItem = Object.assign({}, item)
      this.showTransferDialog = true
    },
    async refreshItems() {
      this.refreshLoading = true
      await this.$store.dispatch(
        "fetchMachines",
        this.$store.getters.tenantRoot.uid
      )
      this.refreshLoading = false
    }
  },
  computed: {
    ...mapGetters(["isAdmin", "isLocalAdmin", "isProduction"]),

    snackbarText() {
      return this.$t("common.saveSuceeded")
    },
    machineImageTitle() {
      if (this.machine && !this.machineImageMissing) {
        return ""
      } else {
        return this.$t("machines.missingMachineImage")
      }
    },
    machineImageSrc() {
      if (
        this.machine.properties !== null &&
        this.machine.properties.tags !== null
      ) {
        if (this.machine.properties.tags.includes("smart_power_plus")) {
          return `/products/jpg/${this.machine.productId}_SPP.jpg`
        }
      }

      return `/products/jpg/${this.machine.productId}.jpg`
    },
    networkType() {
      if (this.machine && !this.machine.unprovisioned) {
        if (this.machine.networkType === "Wi-Fi") {
          return "mdi-wifi"
        }
        return "mdi-signal-cellular-3"
      }
      // No machine info or machine is unprovisioned
      return null
    },
    networkTypeText() {
      if (this.machine && !this.machine.unprovisioned) {
        if (this.machine.networkType === "Wi-Fi") {
          return "Wi-Fi"
        }
        return "3G/4G"
      }
      // No machine info or machine is unprovisioned
      return ""
    },
    machine() {
      return this.$store.getters.machineBySerialNo(this.serialNo)
    },
    pendingOrOverdueService() {
      return (
        (this.machine.serviceStatus.includes("overdue") &&
          !this.machine.unprovisioned) ||
        this.machine.serviceStatus.includes("pending")
      )
    },
    badgeColorByPendingOrOverdue() {
      if (this.machine.serviceStatus.includes("pending")) {
        return "orange"
      }
      return "error"
    },
    canReadAlarms() {
      return this.$store.getters.canReadAlarms
    },
    canReadParameters() {
      return this.$store.getters.canReadParameters
    },
    canReadSystem() {
      return this.$store.getters.canReadSystem
    },
    canReadMeasurements() {
      return this.$store.getters.canReadMeasurements
    },
    canReadMaintenance() {
      return this.$store.getters.canReadMaintenance
    },
    canReadRental() {
      return this.$store.getters.canReadRental
    },
    canReadLocation() {
      return (
        this.$store.getters.canReadGeolocation ||
        this.$store.getters.canReadGeofence
      )
    },
    canReadMachineInfo() {
      return this.isAdmin || this.isLocalAdmin
    },
    alarmBadge() {
      if (this.machine.activeEvents.length > 0) {
        return {
          content: this.machine.activeEvents.length,
          color: "error"
        }
      }
      return null
    },
    systemBadge() {
      // TODO: Enable once software download has been released
      /*
      if (this.machine.hasSoftwareUpdate) {
        return {
          content: "i",
          color: "info"
        }
      }*/
      return null
    },
    menuItems() {
      let items = []
      items.push({
        icon: "mdi-view-dashboard",
        title: this.$t("machines.menuItems.dashboard"),
        link: "dashboard"
      })

      if (this.isProduction) {
        items.push({
          icon: "mdi-wrench-check",
          title: "Inspection",
          link: "inspection"
        })
        items.push({
          icon: "mdi-cogs",
          title: this.$t("machines.menuItems.parameters"),
          link: "parameters"
        })
        return items
      }

      if (this.canReadMachineInfo && !this.machine.unprovisioned) {
        items.push({
          icon: "mdi-information",
          title: this.$t("machines.menuItems.info"),
          link: "info"
        })
      }

      if (this.machine.unprovisioned === false && this.canReadAlarms) {
        items.push({
          icon: "mdi-bell-ring",
          title: this.$t("machines.menuItems.alarms"),
          link: "alarms",
          badge: this.alarmBadge
        })
        if (this.canReadMeasurements) {
          items.push({
            icon: "mdi-chart-line",
            title: this.$t("machines.menuItems.measurements"),
            link: "measurements"
          })
        }
      }

      if (this.canReadMaintenance) {
        if (this.machine.unprovisioned === false) {
          items.push({
            icon: "mdi-tools",
            title: this.$t("machines.menuItems.maintenance"),
            link: "maintenance",
            badge: this.pendingOrOverdueService
              ? {
                  icon: "mdi-exclamation-thick",
                  color: this.badgeColorByPendingOrOverdue
                }
              : null
          })
        } else {
          items.push({
            icon: "mdi-tools",
            title: this.$t("machines.menuItems.maintenance"),
            link: "maintenance"
          })
        }
      }

      if (this.machine.unprovisioned === false) {
        if (this.canReadRental) {
          items.push({
            icon: "mdi-key-variant",
            title: this.$t("machines.menuItems.rental"),
            link: "rental"
          })
        }
        if (this.canReadLocation) {
          items.push({
            icon: "mdi-map-marker-radius",
            title: this.$t("machines.menuItems.location"),
            link: "location"
          })
        }
        if (this.canReadParameters) {
          items.push({
            icon: "mdi-cogs",
            title: this.$t("machines.menuItems.parameters"),
            link: "parameters"
          })
        }

        if (this.canReadSystem) {
          items.push({
            icon: "mdi-chip",
            title: this.$t("machines.menuItems.system"),
            link: "system",
            badge: this.systemBadge
          })
        }
      }
      items.push({
        icon: "mdi-book-open-variant",
        title: this.$t("mainMenu.documents"),
        link: "documents"
      })

      return items
    },
    canEditItem() {
      return this.$store.getters.canEditMachines
    }
  },
  watch: {
    machine() {
      // Set active alarms from machine.
      // If the alarms tab is opened, this will be refreshed.
      this.$store.commit("setActiveAlarms", this.machine.activeEvents)
    }
  }
}
</script>
<style type="scss">
.machine-view-list-nav {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 0;
}
.machine-view-list-nav .v-list-item--link:before {
  background-color: inherit;
}
.v-list-item--dense .v-list-item__title {
  font-size: 1rem;
  font-weight: 400;
}
.machine-view-active-item .v-list-item__title {
  font-size: 1rem;
  font-weight: 700;
}
.machine-avatar {
  max-width: 100%;
}
.image-stack {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
}
.image-stack-item-top {
  grid-row: 1;
  grid-column: 1 / span 6;
  align-self: stretch;
  margin-top: 2em;
  margin-right: 1em;
  z-index: 1;
}
.image-stack-item-bottom {
  grid-row: 1;
  grid-column: 1 / -1;
}

.v-application .border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.v-application .border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
