<template>
  <v-card ref="chartContainer">
    <!-- Unprovisioned machine title -->
    <div v-if="this.disabled" class="text-center mb-0 disabled">
      <p class="pb-0 mb-0 text-uppercase text-h6 two-lines-text-truncate">
        {{ title }}
      </p>
    </div>

    <!-- Provisioned machine title -->
    <div v-else class="secondary text-center">
      <p class="text-uppercase text-h6">{{ title }}</p>
    </div>
    <v-card-text class="pt-0">
      <v-row>
        <!-- Dropdown menu -->
        <v-col :lg="datePickerSizeLg">
          <v-select
            :items="valuesObjectSelectionList"
            v-model="selectedTimeRange"
            @change="updateDropDownChoice"
            :label="$t('common.filters.timeRange')"
          ></v-select>
        </v-col>
        <v-col :lg="datePickerSizeLg">
          <!-- Custom date -->
          <v-menu
            ref="datePickerMenu"
            v-model="datePickerMenu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                class="v-text-field"
                v-model="dateRangeText"
                readonly
                :placeholder="$t('common.placeholders.selectDates')"
                :disabled="selectedTimeRange !== 'custom'"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates" range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="datePickerMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                text
                color="primary"
                :disabled="dates[0] === undefined || dates[1] === undefined"
                @click="updateCustomDates"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <!-- Machine overview piechart -->
        <v-col cols="12" :lg="activityWidgetSizeLg">
          <v-card outlined class="mt-2">
            <v-card-text>
              <apexchart
                type="pie"
                height="100%"
                :options="chartOptions"
                :series="series"
              />
            </v-card-text>
            <v-card-actions>
              {{ $t("common.total") }}: {{ total }}
              {{ $t("common.units.hourShort") }}
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- Machine tool piechart -->
        <v-col cols="12" lg="6" v-if="subChart">
          <v-card outlined class="mt-2">
            <v-card-text>
              <apexchart
                type="pie"
                height="100%"
                :options="subChartOptions"
                :series="subSeries"
              />
            </v-card-text>
            <v-card-actions>
              {{ $t("common.total") }}: {{ toolTotal }}
              {{ $t("common.units.hourShort") }}
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-overlay
      v-if="this.disabled"
      absolute
      color="#fff"
      opacity=".9"
      class="text--primary"
    >
      <h1 class="title d-flex mb-5">
        <v-icon color="primary" class="mr-2">mdi-information-outline</v-icon>
        {{ $t("machines.featureNotAvailable") }}
      </h1>
    </v-overlay>
  </v-card>
</template>
<script>
import VueApexCharts from "vue-apexcharts"
import colors from "vuetify/lib/util/colors"

export default {
  name: "PieExtended",
  props: {
    title: String,
    subChart: Boolean,
    metricsData: Object,
    subMetricsData: Object,
    disabled: Boolean,
    formatHours: Boolean,
    total: Number,
    toolTotal: Number,
    valuesObjectSelectionList: Array,
    dropDownModelValue: String,
    legendFormatter: { default: seriesName => seriesName },
    legendType: {
      validator(value) {
        return ["vertical", "horizontal"].includes(value)
      },
      default: "vertical"
    }
  },
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      apexChartWidth: 400,
      apexChartHeight: null,
      lastContainerWidth: 0,
      selectedTimeRange: null,
      dates: [],
      datePickerMenu: false,
      dateRangeText: ""
    }
  },
  created() {
    // Updated the component's model value from the property
    this.selectedTimeRange = this.dropDownModelValue
  },
  // bind event handlers to the `handleResize` method (defined below)
  mounted: function() {
    window.addEventListener("resize", this.handleResize)
    this.handleResize()
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize)
  },
  methods: {
    handleResize() {
      let containerWidth = this.$refs.chartContainer.$el.clientWidth

      if (Math.abs(containerWidth - this.lastContainerWidth) > 100) {
        if (containerWidth > 800) {
          containerWidth = 800
        }
        this.apexChartWidth = containerWidth - 25
        this.lastContainerWidth = containerWidth
      }

      if (!this.apexChartHeight) {
        this.apexChartHeight = this.$refs.chartContainer.$el.clientHeight - 40
      }
      this.apexChartHeight = 100
    },
    updateDropDownChoice() {
      // Time range selection changed, update the property value owned by the parent component.
      this.$emit("update:dropDownModelValue", this.selectedTimeRange)
    },
    updateDatePickerMenu() {
      this.$emit("update:datePickerMenu", (this.datePickerMenu = false))
    },
    updateCustomDates() {
      this.$refs.datePickerMenu.save(this.dates)
      this.$emit("update:dates", this.dates)
    }
    // Update chosen dates and hide the date picker after "ok"
  },
  watch: {
    selectedTimeRange(newRange) {
      if (
        newRange !== "custom" &&
        (this.dateRangeText !== "" || this.dates != [])
      ) {
        // To clear the select when choosing a different dropdown option
        this.dateRangeText = ""
        this.dates = []
        this.updateCustomDates()
      }
      if (newRange == "custom") {
        // Display date picker. OK callback will trigger fetch
        this.datePickerMenu = true
      }
    },
    dates(newDates) {
      // Always set earliest date as start date
      if (!newDates || newDates.length !== 2) {
        this.dateRangeText = ""
        return
      }
      if (newDates[0] > newDates[1]) {
        newDates.reverse()
      }
      this.dateRangeText = newDates.join(" ~ ")
    }
  },
  computed: {
    datePickerSizeLg() {
      if (this.subChart) {
        return "3"
      }
      return "6"
    },
    activityWidgetSizeLg() {
      if (this.subChart) {
        return "6"
      }
      return "12"
    },
    series() {
      return this.metricsData.dataSet
    },
    chartOptions() {
      return {
        chart: {
          offsetX: 0,
          fontFamily: "Roboto, sans-serif"
        },
        noData: {
          text: this.$i18n.t("$vuetify.noDataText"),
          style: {
            fontFamily: "Roboto, sans-serif"
          }
        },
        tooltip: {
          y: this.formatHours
            ? {
                formatter: function(value) {
                  return `${Math.floor(value / 3600)} h`
                }
              }
            : {}
        },
        colors: [
          colors.orange.darken1,
          colors.green.lighten2,
          colors.blueGrey.darken3,
          colors.amber.lighten2
        ],
        dataLabels: {
          enabled: true,
          dropShadow: false,
          formatter: function(val) {
            return val.toFixed(0) + " %"
          },
          style: {
            fontFamily: "Roboto, sans-serif",
            fontWeight: 300
          }
        },
        legend: {
          show: this.series.length > 0,
          position: "right",
          markers: {
            radius: 3,
            width: 24,
            height: 10
          },
          formatter: this.legendFormatter
        },
        plotOptions: {
          pie: {
            offsetY: 0,
            customScale: 1.0,
            dataLabels: {
              offset: -15
            },
            donut: {
              size: "50%"
            }
          }
        },
        labels: this.metricsData.labels
      }
    },
    subSeries() {
      return this.subMetricsData.dataSet
    },
    subChartOptions() {
      return {
        chart: {
          offsetX: 0,
          fontFamily: "Roboto, sans-serif"
        },
        noData: {
          text: this.$i18n.t("$vuetify.noDataText"),
          style: {
            fontFamily: "Roboto, sans-serif"
          }
        },
        tooltip: {
          y: this.formatHours
            ? {
                formatter: function(value) {
                  return `${Math.floor(value / 3600)} h`
                }
              }
            : {}
        },
        colors: [
          colors.orange.darken1,
          colors.green.lighten2,
          colors.blueGrey.darken3,
          colors.amber.lighten2
        ],
        dataLabels: {
          enabled: true,
          dropShadow: false,
          formatter: function(val) {
            return val.toFixed(0) + " %"
          },
          style: {
            fontFamily: "Roboto, sans-serif",
            fontWeight: 300
          }
        },
        legend: {
          show: this.series.length > 0,
          position: "right",
          markers: {
            radius: 3,
            width: 24,
            height: 10
          },
          formatter: this.legendFormatter
        },
        plotOptions: {
          pie: {
            offsetY: 0,
            customScale: 0.8,
            dataLabels: {
              offset: -15
            },
            donut: {
              size: "50%"
            }
          }
        },
        labels: this.subMetricsData.labels
      }
    }
  }
}
</script>
