<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-tabs
          v-model="tab"
          background-color="white"
          color="black accent-4"
          left
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#alarms">
            <v-icon class="mr-2" small>mdi-bell-ring</v-icon>
            <v-badge
              bordered
              color="error"
              v-if="activeAlarms.length > 0"
              :content="activeAlarms.length"
            >
              {{ $t("machines.tabHeaders.alarms") }}
            </v-badge>
            <span v-else>
              {{ $t("machines.tabHeaders.alarms") }}
            </span>
          </v-tab>
          <v-tab href="#events" v-if="showEvents">
            <v-icon class="mr-2" small>mdi-bell-outline</v-icon>
            {{ $t("machines.tabHeaders.events") }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-tabs-items v-model="tab">
      <!-- ALARMS -->
      <v-tab-item value="alarms">
        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="isLoadingAlarms"
              :headers="alarmHeaders"
              :items="activeAlarms"
              :items-per-page="15"
              :search="search"
              item-key="itemNo"
              sort-by="severity"
              sort-desc
            >
              <template v-slot:[`item.function`]="{ item }">
                <span>{{ formatFunction(item) }} </span>
              </template>
              <template v-slot:[`item.parameterId`]="{ item }">
                <span>{{ item.parameterId }} </span>
              </template>
              <template v-slot:[`item.severity`]="{ item }">
                <span>{{ severityLabel(item.severity) }}</span>
              </template>
              <template v-slot:[`item.engineSeconds`]="{ item }">
                <span>{{ item.engineSeconds | engineHourFilter }}</span>
              </template>
              <template v-slot:[`item.alarm.engineSeconds`]="{ item }">
                <span>{{ item.alarm.engineSeconds | engineHourFilter }}</span>
              </template>
              <template v-slot:[`item.receiveTime`]="{ item }">
                <span>{{ item.receiveTime | localeDateFilter }}</span>
              </template>
              <template v-slot:[`item.alarm.receiveTime`]="{ item }">
                <span>{{ item.alarm.receiveTime | localeDateFilter }}</span>
              </template>
              <template v-slot:[`item.extraData`]="{ item }">
                <span v-if="item.extraData" @click="showExtraInfo(item)">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon small v-on="on">mdi-information</v-icon>
                    </template>
                    <div
                      v-for="part in splitExtraData(item.extraData)"
                      :key="part.lineNo"
                    >
                      {{ part.paramName }}
                    </div>
                  </v-tooltip>
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>

      <!-- EVENTS -->
      <v-tab-item value="events" v-if="showEvents">
        <v-row>
          <v-col cols="12" sm="12" lg="5">
            <v-text-field
              v-model="search"
              prepend-icon="mdi-magnify"
              :label="$t('common.filters.filter')"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-select
              :items="timeRangeItems"
              item-value="value"
              v-model="selectedTimeRange"
              :label="$t('common.filters.timeRange')"
              :disabled="isLoadingEventHistory"
            ></v-select>
          </v-col>
          <v-col cols="11" sm="5" lg="3">
            <v-menu
              ref="datePickerMenu"
              v-model="datePickerMenu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dateRangeText"
                  readonly
                  :placeholder="$t('common.placeholders.selectDates')"
                  :disabled="selectedTimeRange !== 'custom'"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="dates" range>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="datePickerMenu = false">{{
                  $t("common.actions.cancel")
                }}</v-btn>
                <v-btn
                  text
                  color="primary"
                  :disabled="dates[0] === undefined || dates[1] === undefined"
                  @click="
                    $refs.datePickerMenu.save(dates)
                    onSavePickedDate()
                  "
                  >{{ $t("common.actions.ok") }}</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="1" class="pl-0 d-flex justify-start align-center">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  icon
                  @click="fetchEventHistory"
                  :loading="refreshLoading"
                  :disabled="refreshLoading"
                >
                  <v-icon>mdi-cached</v-icon>
                  <template v-slot:loader>
                    <span class="refreshLoader">
                      <v-icon>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-btn>
              </template>
              <span>{{ $t("common.actions.refresh") }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :loading="isLoadingEventHistory"
              :headers="eventHeaders"
              :items="eventHistory"
              :items-per-page="15"
              :search="search"
              item-key="itemNo"
              sort-by="receiveTime"
              sort-desc
            >
              <template v-slot:[`item.parameterId`]="{ item }">
                <span>{{ item.parameterId }} </span>
              </template>
              <template v-slot:[`item.active`]="{ item }">
                <span v-if="item.active">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon :color="severityColor(item.severity)" v-on="on">{{
                        severityIcon(item.severity)
                      }}</v-icon>
                    </template>
                    <span>{{ $t("machines.dataTable.headers.active") }}</span>
                  </v-tooltip>
                </span>
              </template>

              <template v-slot:[`item.severity`]="{ item }">
                <span>{{ severityLabel(item.severity) }}</span>
              </template>
              <template v-slot:[`item.engineSeconds`]="{ item }">
                <span>{{ item.engineSeconds | engineHourFilter }}</span>
              </template>
              <template v-slot:[`item.receiveTime`]="{ item }">
                <span>{{ item.receiveTime | localeDateFilter }}</span>
              </template>
              <template v-slot:[`item.extraData`]="{ item }">
                <span v-if="item.extraData" @click="showExtraInfo(item)">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon small v-on="on">mdi-information</v-icon>
                    </template>
                    <div
                      v-for="part in splitExtraData(item.extraData)"
                      :key="part.lineNo"
                    >
                      {{ part.paramName }}
                    </div>
                  </v-tooltip>
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>

    <extra-info-dialog v-model="extraInfoDialog" :item="extraInfoItem" />
  </v-container>
</template>
<script>
import Severity from "@/utils/severity"
import EventFilter from "@/filters/eventfilter"
import ExtraInfoDialog from "@/components/ExtraInfoDialog"

export default {
  name: "MachineAlarms",
  props: ["tenantUid", "serialNo"],
  components: { ExtraInfoDialog },
  data() {
    return {
      tab: "alarms",
      showEvents: false,
      refreshLoading: true,
      search: "",
      dates: [],
      extraInfoDialog: false,
      extraInfoItem: null,

      datePickerMenu: false,

      selectedTimeRange: "today",
      dateRangeText: ""
    }
  },
  async created() {
    this.setDateRange()
    const langCode = this.$store.getters.dataLangCode

    if (this.$store.getters.eventMetadata.length === 0) {
      await this.$store.dispatch("loadEventMetadata", langCode)
    }

    if (this.$store.getters.measurementMetadata.length === 0) {
      await this.$store.dispatch("loadMeasurementMetadata", langCode)
    }
  },
  mounted() {
    this.fetchActiveAlarms()
  },
  computed: {
    machine() {
      return this.$store.getters.machineBySerialNo(this.serialNo)
    },
    isSmartPowerPlus() {
      return this.machine.properties?.tags?.includes("smart_power_plus")
    },
    alarmHeaders() {
      if (this.isSmartPowerPlus) {
        return [
          {
            text: this.$t("machines.dataTable.headers.function"),
            value: "function"
          },
          {
            text: this.$t("machines.dataTable.headers.latestTrigger"),
            value: "alarm.engineSeconds"
          },
          {
            text: this.$t("machines.dataTable.headers.count"),
            value: "alarm.count"
          },
          {
            text: this.$t("machines.dataTable.headers.date"),
            value: "alarm.receiveTime"
          }
        ]
      }

      return [
        {
          text: this.$t("machines.dataTable.headers.code"),
          value: "parameterId"
        },
        {
          text: this.$t("machines.dataTable.headers.description"),
          value: "description"
        },
        {
          text: this.$t("machines.dataTable.headers.severity"),
          value: "severity"
        },
        {
          text: this.$t("machines.dataTable.headers.engineHours"),
          value: "engineSeconds"
        },
        {
          text: this.$t("machines.dataTable.headers.date"),
          value: "receiveTime"
        },
        {
          text: this.$t("machines.dataTable.headers.extraInfo"),
          value: "extraData"
        }
      ]
    },
    eventHeaders() {
      return [
        {
          text: this.$t("machines.dataTable.headers.code"),
          value: "parameterId"
        },
        { text: this.$t("machines.dataTable.headers.active"), value: "active" },
        {
          text: this.$t("machines.dataTable.headers.description"),
          value: "description"
        },
        {
          text: this.$t("machines.dataTable.headers.severity"),
          value: "severity"
        },
        {
          text: this.$t("machines.dataTable.headers.engineHours"),
          value: "engineSeconds"
        },
        {
          text: this.$t("machines.dataTable.headers.date"),
          value: "receiveTime"
        },
        {
          text: this.$t("machines.dataTable.headers.extraInfo"),
          value: "extraData"
        }
      ]
    },
    timeRangeItems() {
      return [
        { text: this.$t("common.enums.day.today"), value: "today" },
        {
          text: `${this.$t("common.filters.last")} ${this.$tc(
            "common.units.day",
            7
          )}`,
          value: "7 days"
        },
        {
          text: `${this.$t("common.filters.last")} ${this.$tc(
            "common.units.day",
            30
          )}`,
          value: "30 days"
        },
        { text: this.$t("common.filters.customRange"), value: "custom" }
      ]
    },
    activeAlarms() {
      if (this.isSmartPowerPlus) {
        let alarms = this.$store.getters.groupAlarms
        return alarms
      }

      let alarms = this.$store.getters.activeAlarms
      for (let ix = 0; ix < alarms.length; ix++) {
        alarms[ix].description = EventFilter(alarms[ix].parameterId)
      }
      return alarms
    },
    isLoadingAlarms() {
      return this.$store.getters.loadingAlarms
    },
    eventHistory() {
      let events = this.$store.getters.eventHistory
      for (let ix = 0; ix < events.length; ix++) {
        events[ix].description = EventFilter(events[ix].parameterId)
      }
      return events
    },
    isLoadingEventHistory() {
      return this.$store.getters.loadingEventHistory
    }
  },
  methods: {
    showExtraInfo(item) {
      this.extraInfoItem = item
      this.extraInfoDialog = true
    },
    setDateRange() {
      let startDate = new Date()
      let offset = 0

      if (this.selectedTimeRange === "today") {
        offset = 0
      } else if (this.selectedTimeRange === "7 days") {
        offset = 6 // Becomes 7 since today will be included
      } else if (this.selectedTimeRange === "30 days") {
        offset = 29 // Becomes 30 since today will be included
      }

      startDate.setDate(startDate.getDate() - offset)
      this.dates = [
        startDate.toISOString().substring(0, 10),
        new Date().toISOString().substring(0, 10)
      ]
    },
    fetchActiveAlarms() {
      if (this.isSmartPowerPlus) {
        this.$store.dispatch("fetchGroupAlarms", {
          tenantUid: this.tenantUid,
          serialNo: this.serialNo
        })
      } else {
        this.$store.dispatch("fetchActiveAlarms", {
          tenantUid: this.tenantUid,
          serialNo: this.serialNo
        })
      }
    },
    async fetchEventHistory() {
      this.refreshLoading = true
      try {
        await this.$store.dispatch("fetchEventHistory", {
          tenantUid: this.tenantUid,
          serialNo: this.serialNo,
          start: this.dates[0],
          end: this.dates[1]
        })
      } catch (error) {
        // Error already handled
      } finally {
        this.refreshLoading = false
      }
    },
    severityLabel(severity) {
      return Severity.label(severity)
    },
    severityIcon(severity) {
      return Severity.eventIcon(severity)
    },
    severityColor(severity) {
      return Severity.eventColor(severity)
    },
    splitExtraData(extraInfo) {
      var result = []

      // Maximum number of extras to display
      const MAX_NUM_EXTRA = 5

      const parts = extraInfo.split("|")
      for (let ix = 0; ix < Math.min(parts.length, MAX_NUM_EXTRA); ix++) {
        result.push({ lineNo: ix, paramName: parts[ix] })
      }
      if (parts.length > MAX_NUM_EXTRA) {
        result.push({
          lineNo: result.length,
          paramName: `...${this.$t("machines.alarms.moreExists")}`
        })
      }
      return result
    },
    onSavePickedDate() {
      this.datePickerMenu = false
      this.dateRangeText = this.dates.join(" ~ ")
      this.fetchEventHistory()
    },
    formatFunction(item) {
      let fn = `${item.metadata.title} / ${item.metadata.text1} ${item.metadata.text2} ${item.metadata.text3}`
      if (item.showSplashText) {
        fn = `${fn}. ${item.metadata.splashText}`
      }
      return fn
    }
  },
  watch: {
    tab(newTab) {
      if (newTab === "events") {
        this.fetchEventHistory()
      } else {
        this.fetchActiveAlarms()
      }
    },
    selectedTimeRange(newRange) {
      if (newRange !== "custom") {
        this.setDateRange()
        this.fetchEventHistory()
      } else {
        // Display date picker. OK callback will trigger fetch
        this.datePickerMenu = true
      }
    },
    dates(newDates) {
      // Always set earliest date as start date
      if (newDates[0] > newDates[1]) {
        newDates.reverse()
      }
      this.dateRangeText = newDates.join(" ~ ")
    }
  }
}
</script>
<style></style>
