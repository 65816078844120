<template>
  <v-container fluid v-if="machineDashboard">
    <v-row>
      <v-col cols="12" sm="6" lg="3" v-if="this.canReadMeasurements">
        <!-- Engine hours -->
        <ValueWidget
          :title="$t('machines.labels.engineHours')"
          :valuesObject="machineEngineHoursSummary"
          :valuesObjectSelectionList="machineActivityTimeRangeSelectionList"
          @lazyLoad="fetchEngineHoursSummary"
          :valueFilter="engineHourFilterInner"
          :loading="loadingMachineEngineHoursSummary"
          :text="
            formatLastUpdated(machineDashboard.timing.engineTime.lastUpdated)
          "
          backgroundIcon="mdi-engine"
          :backgroundIconColor="engineRunningColor"
          actionIcon="mdi-chart-line"
          actionPath="measurements"
          actionDetailsLink="engine-hours"
          disable="true"
          :hideActions="machine.unprovisioned"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="6" v-if="this.canReadMaintenance">
        <!-- Next service -->
        <NextServiceWidget
          :upcoming="machineDashboard.maintenance.upcoming"
          action-link="maintenance"
          action-icon="mdi-tools"
          :disabled="machine.unprovisioned"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <ValueWidget
          :title="$t('machines.labels.activeAlarms')"
          :value="activeAlarms.length"
          :valueColor="activeAlarms.length > 0 ? 'accent--text' : 'white--text'"
          :text="lastAlarmReset"
          :backgroundIcon="
            activeAlarms.length > 0 ? 'mdi-bell-ring' : 'mdi-bell'
          "
          :backgroundIconColor="alarmColor"
          actionIcon="mdi-bell-ring-outline"
          actionPath="alarms"
          actionDetailsLink=""
          :disabled="machine.unprovisioned"
        />
      </v-col>

      <v-col
        cols="12"
        :lg="activityWidgetSizeLg"
        v-if="this.canReadMeasurements && machineActivity && !isSmartPowerPlus"
      >
        <PieExtended
          :title="$t('machines.labels.machineActivity')"
          :subChart="false"
          :metricsData="activityData"
          :disabled="machine.unprovisioned"
          formatHours
          :total="totalMachineActiveTimeInHours"
          :valuesObjectSelectionList="machineActivityTimeRangeSelectionList"
          :dropDownModelValue.sync="activitySelectInput"
          :dates.sync="customDates"
          @onDateChange="updateChosenDates"
        />
      </v-col>

      <v-col
        cols="12"
        :lg="activityWidgetSizeLg"
        v-if="this.canReadMeasurements && machineActivity && isSmartPowerPlus"
      >
        <PieExtended
          :title="$t('machines.labels.machineAndToolActivity')"
          :subChart="true"
          :metricsData="activityData"
          :subMetricsData="subActivityData"
          :disabled="machine.unprovisioned"
          formatHours
          :total="totalMachineActiveTimeInHours"
          :toolTotal="totalToolActiveTimeInHours"
          :valuesObjectSelectionList="machineActivityTimeRangeSelectionList"
          :dropDownModelValue.sync="activitySelectInput"
          :dates.sync="customDates"
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="3"
        v-if="this.canReadMeasurements && this.hasDieselEngine"
      >
        <SolidGauge
          v-if="dieselFuel"
          :title="$t('machines.labels.fuelLevel')"
          :text="formatLastUpdated(dieselFuel.lastUpdated)"
          :value="
            ((dieselFuel.currentLevel / dieselFuel.maxLevel) * 100)
              | dataFilter('toInteger')
          "
          :gaugeMin="0"
          :gaugeMax="100"
          metricsLabel="%"
          :invertedColorLimits="true"
          :disabled="machine.unprovisioned"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3" v-if="this.canReadMeasurements">
        <SolidGauge
          v-if="machineDashboard.temp.hydraulics"
          :title="$t('machines.labels.hydraulicFluidTemp')"
          :text="
            formatLastUpdated(machineDashboard.temp.hydraulics.lastUpdated)
          "
          :value="
            machineDashboard.temp.hydraulics.currentVal
              | temperatureFilter(this.units)
              | dataFilter('toInteger')
          "
          :valueAvg="
            machineDashboard.temp.hydraulics.averageVal
              | temperatureFilter(this.units)
              | dataFilter('toInteger')
          "
          :valueMax="
            machineDashboard.temp.hydraulics.maxVal
              | temperatureFilter(this.units)
              | dataFilter('toInteger')
          "
          gaugeMin="0"
          gaugeMax="100"
          :metrics="temperatureName"
          :metricsLabel="temperatureSymbol"
          actionIcon="mdi-chart-line"
          actionPath="measurements"
          actionDetailsLink="temperatures"
          :disabled="machine.unprovisioned"
        />
      </v-col>

      <!-- AMBIENT TEMPERATURE -->
      <v-col cols="12" sm="6" lg="3" v-if="shouldViewAmbientTemp">
        <SolidGauge
          :title="$t('machines.labels.ambientTemperature')"
          :text="formatLastUpdated(machineDashboard.temp.ambient.lastUpdated)"
          :value="ambientTemperature | dataFilter('toInteger')"
          gaugeMin="-40"
          gaugeMax="140"
          :metrics="temperatureName"
          :metricsLabel="temperatureSymbol"
          actionIcon="mdi-chart-line"
          actionPath="measurements"
          actionDetailsLink="temperatures"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="3" v-if="dieselFilterStatus">
        <DieselParticulateFilterWidget :dpfStatus="dieselFilterStatus" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex"

import formatter from "@/utils/formatter"
import temperatureUtils from "@/utils/temperature"
import ValueWidget from "@/components/widgets/ValueWidget"

import SolidGauge from "@/components/widgets/SolidGauge"
import PieExtended from "@/components/widgets/PieExtended"

import localeDateFilter from "@/filters/localedatefilter"
import engineHourFilter from "@/filters/enginehourfilter"
import NextServiceWidget from "@/components/widgets/NextServiceWidget"
import DieselParticulateFilterWidget from "@/components/widgets/DieselParticulateFilterWidget"

let Formatter = null

export default {
  name: "MachineDashboard",
  props: ["machine"],
  components: {
    NextServiceWidget,
    ValueWidget,
    SolidGauge,
    PieExtended,
    DieselParticulateFilterWidget
  },
  data() {
    return {
      Formatter: Formatter,
      dropDownTimeRange: null,
      activitySelectInput: "total",
      customDates: []
    }
  },
  beforeCreate() {
    Formatter = formatter(this.$i18n)
  },
  created() {
    this.fetchDashboardData()
    this.fetchMachineActivityData()
  },
  computed: {
    ...mapGetters([
      "canReadMaintenance",
      "canReadMeasurements",
      "machineDashboard",
      "machineEngineHoursSummary",
      "loadingMachineEngineHoursSummary",
      "machineActivity",
      "loadingMachineActivity"
    ]),
    activityWidgetSizeLg() {
      if (this.isSmartPowerPlus) {
        return "12"
      }
      return "6"
    },
    temperatureSymbol() {
      return temperatureUtils.getSymbol(this.units)
    },
    temperatureName() {
      return temperatureUtils.getName(this.units)
    },
    units() {
      return this.$store.getters.userDetails?.preferences?.units || "metric"
    },
    isRunning() {
      return this.machine !== null && this.machine.status === "running"
    },
    engineRunningColor() {
      if (this.isRunning) {
        return "success--text" // Pre-defined material design color (green-ish)
      }
      return null
    },
    lastAlarmReset() {
      if (this.machineDashboard.timing.lastAlarmResetTime.data > 0) {
        return `Last reset at ${engineHourFilter(
          this.machineDashboard.timing.lastAlarmResetTime.data
        )}`
      }
      return ""
    },
    alarmColor() {
      let alarmColor = "success--text"

      if (this.activeAlarms.length > 0) {
        alarmColor = "warning--text"
      }
      if (this.activeAlarms.length > 3) {
        alarmColor = "error--text"
      }
      return alarmColor
    },
    activeAlarms() {
      return this.machine.activeEvents
    },
    hasDieselEngine() {
      return this.machine.hasDieselEngine
    },
    hasParticulateFilter() {
      return this.machineDashboard.diesel?.filter
    },
    isSmartPowerPlus() {
      return this.machine.properties?.tags?.includes("smart_power_plus")
    },
    shouldViewAmbientTemp() {
      return this.isSmartPowerPlus && this.machineDashboard.temp.ambient
    },
    ambientTemperature() {
      // When last updated is null/missing we don't have any temperature
      if (this.machineDashboard.temp.ambient?.lastUpdated) {
        return this.machineDashboard.temp.ambient.currentVal
      }
      return null
    },
    activeAlarmsLastRefresh() {
      if (this.machine.activeEvents[0] !== undefined) {
        return `${Formatter.formatDaysFromNow(
          this.machine.activeEvents[this.machine.activeEvents.length - 1]
            .receiveTime
        )}`
      }
      return this.lastSeenDays
    },
    lastSeenDays() {
      return Formatter.formatDays(0)
    },
    activitySelectInputActivity() {
      return this.activitySelectInput
    },
    activityData() {
      const hasData =
        this.machineActivity.engineTime > 0 ||
        this.machineActivity.otherTime > 0 ||
        this.machineActivity.toolActiveTimeSP > 0 ||
        this.machineActivity.beltActiveTime > 0

      let dataSet = []

      if (hasData) {
        dataSet = this.buildMetricsData()
      }

      const metricsData = {
        labels: this.buildMetricsLabels(),
        dataSet: dataSet
      }

      return metricsData
    },
    subActivityData() {
      const hasSubData =
        this.machineActivity.totalToolActiveTime >
        this.machineActivity.toolActiveTimeSP
      let dataSet = []

      // for testing how to place pie in widget, will remove
      if (hasSubData) {
        dataSet = this.buildSubMetricsData()
      }
      const subMetricsData = {
        labels: this.buildSubMetricsLabels(),
        dataSet: dataSet
      }

      return subMetricsData
    },
    dieselFilterStatus() {
      // Diesel Particulate Filter
      if (this.hasParticulateFilter) {
        return {
          status: `${this.machineDashboard.diesel.filter.status}`,
          ashLevel: `${this.machineDashboard.diesel.filter.ashLevel} %`,
          sootLevel: `${this.machineDashboard.diesel.filter.sootLevel} %`
        }
      }
      return null
    },
    dieselFuel() {
      if (this.machineDashboard.diesel?.fuel) {
        return this.machineDashboard.diesel.fuel
      }
      return null
    },
    machineActivityTimeRangeSelectionList() {
      return [
        {
          value: "total",
          text: this.$t("machines.engineHoursSelectionList.total")
        },
        {
          value: "lastDay",
          text: this.$t("machines.engineHoursSelectionList.lastDay")
        },
        {
          value: "lastWeek",
          text: this.$t("machines.engineHoursSelectionList.lastWeek")
        },
        {
          value: "lastMonth",
          text: this.$t("machines.engineHoursSelectionList.lastMonth")
        },
        {
          value: "lastQuarter",
          text: this.$t("machines.engineHoursSelectionList.lastQuarter")
        },
        {
          value: "lastHalfYear",
          text: this.$t("machines.engineHoursSelectionList.lastHalfYear")
        },
        {
          value: "custom",
          text: this.$t("machines.engineHoursSelectionList.custom")
        }
      ]
    },
    totalMachineActiveTimeInHours() {
      const timeInHours = Math.floor(this.machineActivity.engineTime / 3600)
      return timeInHours
    },
    totalToolActiveTimeInHours() {
      const toolTimeInHours = Math.floor(
        this.machineActivity.totalToolActiveTime / 3600
      )
      return toolTimeInHours
    },
    customDatesChange() {
      return this.customDates
    },
    engineHoursTotal() {
      return this.machineActivity.engineTime / 3600
    }
  },
  methods: {
    buildMetricsData() {
      let filteredArray = []
      if (this.machineActivity.totalToolActiveTime > 0) {
        filteredArray.push(this.machineActivity.totalToolActiveTime)
      }
      if (this.machineActivity.beltActiveTime > 0) {
        filteredArray.push(this.machineActivity.beltActiveTime)
      }
      if (this.machineActivity.otherTime > 0) {
        filteredArray.push(this.machineActivity.otherTime)
      }
      return filteredArray
    },
    buildMetricsLabels() {
      let filteredArray = []
      if (this.machineActivity.totalToolActiveTime > 0) {
        filteredArray.push(this.$t("machines.activities.toolActiveTime"))
      }
      if (this.machineActivity.beltActiveTime > 0) {
        filteredArray.push(this.$t("machines.activities.beltActiveTime"))
      }
      if (this.machineActivity.otherTime > 0) {
        filteredArray.push(this.$t("machines.activities.otherTime"))
      }
      return filteredArray
    },
    buildSubMetricsData() {
      let filteredArray = []
      if (this.machineActivity.toolActiveTime.customDoubleActing > 0) {
        filteredArray.push(
          this.machineActivity.toolActiveTime.customDoubleActing
        )
      }
      if (this.machineActivity.toolActiveTime.smartTool > 0) {
        filteredArray.push(this.machineActivity.toolActiveTime.smartTool)
      }
      if (this.machineActivity.toolActiveTime.customSingleActing > 0) {
        filteredArray.push(
          this.machineActivity.toolActiveTime.customSingleActing
        )
      }
      if (this.machineActivity.toolActiveTime.breaker > 0) {
        filteredArray.push(this.machineActivity.toolActiveTime.breaker)
      }
      if (this.machineActivity.toolActiveTime.bucket > 0) {
        filteredArray.push(this.machineActivity.toolActiveTime.bucket)
      }
      if (this.machineActivity.toolActiveTime.concreteCrusher > 0) {
        filteredArray.push(this.machineActivity.toolActiveTime.concreteCrusher)
      }
      if (this.machineActivity.toolActiveTime.steelShear > 0) {
        filteredArray.push(this.machineActivity.toolActiveTime.steelShear)
      }
      return filteredArray
    },
    buildSubMetricsLabels() {
      let filteredArray = []
      if (this.machineActivity.toolActiveTime.customDoubleActing > 0) {
        filteredArray.push(this.$t("machines.tools.customDoubleActing"))
      }
      if (this.machineActivity.toolActiveTime.smartTool > 0) {
        filteredArray.push(this.$t("machines.tools.smartTool"))
      }
      if (this.machineActivity.toolActiveTime.customSingleActing > 0) {
        filteredArray.push(this.$t("machines.tools.customSingleActing"))
      }
      if (this.machineActivity.toolActiveTime.breaker > 0) {
        filteredArray.push(this.$t("machines.tools.breaker"))
      }
      if (this.machineActivity.toolActiveTime.bucket > 0) {
        filteredArray.push(this.$t("machines.tools.bucket"))
      }
      if (this.machineActivity.toolActiveTime.concreteCrusher > 0) {
        filteredArray.push(this.$t("machines.tools.concreteCrusher"))
      }
      if (this.machineActivity.toolActiveTime.steelShear > 0) {
        filteredArray.push(this.$t("machines.tools.steelShear"))
      }
      return filteredArray
    },
    fetchDashboardData() {
      this.$store.dispatch("fetchMachineDashboard", this.machine)
    },
    fetchEngineHoursSummary() {
      this.$store.dispatch("fetchMachineEngineHoursSummary", this.machine)
    },
    fetchMachineActivityData() {
      this.$store.dispatch("fetchMachineActivity", {
        machine: this.machine,
        params: this.setWidgetTimeRange()
      })
    },
    formatLastUpdated(lastUpdated) {
      if (lastUpdated !== undefined && lastUpdated !== null) {
        return this.$t("machines.updated", {
          TIME: localeDateFilter(lastUpdated)
        })
      }
      return this.$t("$vuetify.noDataText")
    },
    engineHourFilterInner(engineHour) {
      return engineHourFilter(engineHour)
    },
    setWidgetTimeRange() {
      let startDate = new Date()
      let offset = 0
      let dateParams = { start: "", end: "" }

      if (this.activitySelectInput === "total") {
        return dateParams
      } else if (this.activitySelectInput === "lastDay") {
        offset = 0
      } else if (this.activitySelectInput === "lastWeek") {
        offset = 6 // Becomes 7 since today will be included
      } else if (this.activitySelectInput === "lastMonth") {
        offset = 29 // Becomes 30 since today will be included
      } else if (this.activitySelectInput === "lastQuarter") {
        offset = 89 // Becomes 90 since today will be included
      } else if (this.activitySelectInput === "lastHalfYear") {
        offset = 179 // Becomes 180 since today will be included
      } else if (this.activitySelectInput === "custom") {
        // To avoid picking an end date that's earlier than start date

        let convertedStart = this.customDates.at(0).toString()
        let convertedEnd = this.customDates.at(1).toString()
        dateParams.start = convertedStart
        dateParams.end = convertedEnd
        return dateParams
      }

      startDate.setDate(startDate.getDate() - offset)
      dateParams.start = startDate.toISOString().substring(0, 10)
      dateParams.end = new Date().toISOString().substring(0, 10)

      return dateParams
    },
    updateChosenDates(newDates) {
      this.customDates = newDates
    }
  },
  watch: {
    activitySelectInputActivity: function() {
      if (this.activitySelectInput !== "custom") {
        this.fetchMachineActivityData()
      }
    },
    customDatesChange: function() {
      if (this.activitySelectInput === "custom") {
        this.fetchMachineActivityData()
      }
    }
  }
}
</script>
<style>
.widget-box {
  border: 2px solid #737373;
  border-radius: 5px;
  min-height: 250px;
  position: relative;
  padding-top: 24px;
  overflow: hidden;
  height: 100%;
}
.widget-box .widget-label {
  position: absolute;
  top: 0;
  width: 100%;
  background: #484848;
  color: #eee;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 25px 5px;
}
.widget-box .widget-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #666;
}
.widget-box .measurements-link {
  position: absolute;
  top: 30px;
  right: 5px;
  opacity: 0;
  z-index: 100;
}
.widget-box:hover .measurements-link {
  opacity: 1;
}
.highcharts-credits {
  display: none;
}
</style>
