<template>
  <v-dialog v-model="visible" max-width="600" persistent>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>
          <v-icon class="accent--text mr-4">mdi-plus-circle</v-icon>
          <span class="title accent--text">{{ title }}</span>
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="onCancel">
          <v-icon class="accent--text">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-1 pb-0">
        <v-form ref="ServiceDocumentForm" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12" class="pa-0">
                <p class="font-weight-bold" v-if="action != 'Edit'">
                  {{ updateMessage }}
                </p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pa-0">
                <v-file-input
                  accept=".pdf"
                  :placeholder="
                    `${$t(
                      'documents.serviceDocuments.addEditItemForm.fileuploadLabel'
                    )}`
                  "
                  :label="
                    `${$t(
                      'documents.serviceDocuments.addEditItemForm.uploadFilePlaceholder'
                    )}*`
                  "
                  :rules="fileUploadRules ? [validationRules.required] : []"
                  @change="onFileSelected"
                ></v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  class="pl-1 ml-1"
                  :label="
                    `${$t(
                      'documents.serviceDocuments.addEditItemForm.partNumberLabel'
                    )}`
                  "
                  :placeholder="
                    $t(
                      'documents.serviceDocuments.addEditItemForm.partNumberPlaceholder'
                    )
                  "
                  :rules="[validationRules.required, validationRules.partNo]"
                  v-model="partNo"
                  :disabled="disablePartNoTextfield"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  class="pl-1 ml-1"
                  :label="
                    `${$t(
                      'documents.serviceDocuments.addEditItemForm.descriptionLabel'
                    )}`
                  "
                  :placeholder="
                    $t(
                      'documents.serviceDocuments.addEditItemForm.descriptionPlaceholder'
                    )
                  "
                  :rules="[
                    validationRules.required,
                    v =>
                      (v || '').length <= 256 ||
                      $t('common.validation.errors.commentMaxChars')
                  ]"
                  v-model="description"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6" class="pa-0">
                <v-select
                  class="pl-1 ml-1"
                  :label="
                    `${$t(
                      'documents.serviceDocuments.addEditItemForm.machinesLabel'
                    )}`
                  "
                  v-model="selectedMachine"
                  :items="items()"
                  item-text="productName"
                  item-value="productId"
                  return-object
                ></v-select>
              </v-col>

              <v-col cols="12" sm="5" class="pa-0">
                <v-text-field
                  class="pl-1 ml-1"
                  :label="
                    `${$t(
                      'documents.serviceDocuments.addEditItemForm.serialLabel'
                    )}*`
                  "
                  :disabled="isUpdating"
                  :placeholder="
                    $t(
                      'documents.serviceDocuments.addEditItemForm.serialPlaceholder'
                    )
                  "
                  :rules="[validationRules.serialNumberList]"
                  v-model="serials"
                ></v-text-field>
              </v-col>

              <v-col cols="2" sm="1" class="pa-2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon @click="addItem">
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    $t("documents.serviceDocuments.action.addSerials")
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="pa-0; my-list">
                <v-list style="max-height: 100px" class="overflow-y-auto">
                  <v-list-item v-for="(item, index) in itemsList" :key="index">
                    <v-list-item-title>{{ item }}</v-list-item-title>
                    <v-list-item-action>
                      <v-btn icon @click="removeItem(index)">
                        <v-icon>mdi-minus-circle</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <v-alert
          v-if="errorMsg.length > 0"
          class="ma-0 mt-4"
          type="error"
          elevation="2"
          border="left"
          colored-border
          dense
        >
          {{ errorMsg }}
        </v-alert>
      </v-card-text>

      <!-- --- Save , cancel --- -->

      <v-card-actions class="pr-6 mr-3">
        <v-spacer></v-spacer>
        <v-btn class="mr-2 mb-2" @click.stop="onSave">{{
          $t("common.actions.save")
        }}</v-btn>
        <v-btn class="mb-2 grab-cancel-edit-user" @click.stop="onCancel">{{
          $t("common.actions.cancel")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ValidationRules from "@/utils/validationrules"
import fetchClient from "@/utils/fetchClient"
import { mapActions, mapGetters } from "vuex"

let validationRules = null

export class MachineSerials {
  constructor(name, productId, serials) {
    this.name = name
    this.productId = productId
    this.serials = serials
  }

  toString() {
    return `${this.name} - ${this.serials}`
  }
}

export default {
  name: "ServiceInstructionsDialog",
  props: ["show", "dialogAction", "docType", "editArr"],

  data() {
    return {
      valid: false,
      validationRules: validationRules,
      isUpdating: false,
      error: null,
      webshopAccess: "",
      selectedMachine: {},
      selectedProduct: "",
      newItem: "",
      itemsList: [],
      serials: null,
      currentFile: "",
      partNo: "",
      description: "",
      errorMsg: "",
      action: "",
      updateMessage: "",
      fileUploadRules: true,
      seriaNoTextfieldRules: true,
      disablePartNoTextfield: false
    }
  },
  async created() {},

  mounted() {},

  beforeCreate() {
    validationRules = ValidationRules(this.$i18n)
  },

  computed: {
    ...mapGetters(["productMetadata"]),

    title() {
      if (this.action == "Add" && this.docType == "serviceInstructions") {
        return this.$t("documents.serviceInstructions.action.add")
      } else if (this.action == "Add" && this.docType == "serviceBulletins") {
        return this.$t("documents.serviceBulletins.action.add")
      } else if (
        this.action != "Add" &&
        this.docType == "serviceInstructions"
      ) {
        return this.$t("documents.serviceInstructions.action.edit")
      } else {
        return this.$t("documents.serviceBulletins.action.edit")
      }
    },
    visible: {
      get() {
        return this.show
      }
    }
  },
  watch: {
    show() {
      this.selectedMachine = { productId: "300", productName: "Brokk 300" }
      this.errorMsg = ""
    },
    dialogAction(newValue) {
      this.updateMessage = ""
      this.action = newValue
      this.disablePartNoTextfield = false
      this.fileUploadRules = true
      this.seriaNoTextfieldRules = true

      if (newValue != "Add" && newValue != "") {
        this.disablePartNoTextfield = true
        this.fileUploadRules = false

        this.document = this.editArr[0]
        this.partNo = this.editArr[1]
        this.description = this.editArr[2]

        const date = new Date(this.editArr[3])
        const addedDate = date.toISOString().slice(0, 10)
        const embeddedArray = this.editArr[4]

        for (const value of embeddedArray) {
          value.name = value.name
            .replace("descsingle", "Descaler Single")
            .replace("desctwin", "Descaler Twin")
            .replace("spp", "SmartPower⁺")
            .replace("sp", "Demolition robots")
          this.itemsList.push(value)
        }

        this.updateMessage =
          this.$t("documents.serviceInstructions.messages.updateDocumentInfo", {
            document: this.document
          }) +
          this.$t("documents.serviceInstructions.messages.updateDateInfo", {
            date: addedDate
          })
      }
    }
  },

  methods: {
    ...mapActions(["setError"]),
    items() {
      const availableProducts = this.productMetadata?.filter(prod => {
        return prod.groupId !== "discont"
      })
      availableProducts.push({
        productId: "spp",
        productName: "Brokk SmartPower⁺"
      })
      availableProducts.push({
        productId: "sp",
        productName: "Brokk Demolition robots"
      })
      const selectData = availableProducts.map(item => ({
        productId: item.productId,
        productName: item.productName
      }))

      return selectData
    },

    onCancel() {
      this.resetForm("cancel")
    },
    async onSave() {
      //---- Add -----
      this.errorMsg = ""

      if (this.action == "Add") {
        if (!this.$refs.ServiceDocumentForm.validate()) {
          return
        }

        if (this.itemsList.length === 0) {
          this.errorMsg = this.$t("common.validation.errors.seriaNoListEmpty")
          return
        } else {
          this.errorMsg = ""
        }

        try {
          await this.$store.dispatch("addServiceDocuments", {
            docType: this.docType,
            partNo: this.partNo,
            document: this.partNo + ".pdf",
            description: this.description,
            products: this.itemsList
          })

          let S3Path = "open/"
          if (this.docType == "serviceInstructions") {
            S3Path += "service_instructions/"
          } else {
            S3Path += "service_bulletins/"
          }

          const filename = S3Path + this.partNo + ".pdf"
          await fetchClient(this.$i18n).awsPutOrThrow(
            filename,
            this.currentFile
          )

          this.resetForm("save")
        } catch (errMsg) {
          this.errorMsg = this.$t(
            "documents.serviceInstructions.dialogs.errors.couldNotAddServiceInstructions",
            { msg: errMsg }
          )
        }
      } else {
        //---- Update -----

        this.errorMsg = ""

        if (!this.$refs.ServiceDocumentForm.validate()) {
          return
        }

        if (this.itemsList.length === 0) {
          this.errorMsg = this.$t("common.validation.errors.seriaNoListEmpty")
          return
        } else {
          this.errorMsg = ""
        }

        const documentName =
          this.currentFile.length === 0 ? "" : this.partNo + ".pdf"

        try {
          var path = "open/"
          var errorTxt = ""

          if (this.docType == "serviceInstructions") {
            path += "service_instructions/" + this.partNo + ".pdf"
            errorTxt =
              "documents.serviceInstructions.dialogs.errors.couldNotUpdateServiceInstructions"
          } else {
            path += "service_bulletins/" + this.partNo + ".pdf"
            errorTxt =
              "documents.serviceBulletins.dialogs.errors.couldNotUpdateServiceBulletins"
          }
          await this.$store.dispatch("updateServiceDocuments", {
            partNo: this.partNo,
            document: documentName,
            description: this.description,
            products: this.itemsList,
            docType: this.docType
          })

          if (documentName != "") {
            await fetchClient(this.$i18n).awsPutOrThrow(path, this.currentFile)
          }

          this.resetForm("update")
        } catch (errMsg) {
          this.errorMsg = this.$t(errorTxt, { msg: errMsg })
        }
      }
    },

    resetForm($action) {
      this.$refs.ServiceDocumentForm.reset()
      this.itemsList = []
      this.currentFile = ""
      this.errorMsg = ""
      this.$emit($action)
    },
    addItem() {
      if (this.serials == null || this.serials === undefined) {
        this.errorMsg = this.$t("common.validation.errors.seriaNoInvalid")
        return
      } else {
        this.errorMsg = ""
      }

      const machineSerial = new MachineSerials(
        this.selectedMachine.productName,
        this.selectedMachine.productId,
        this.serials
      )

      if (this.itemsList.find(i => i.toString() == machineSerial.toString())) {
        this.errorMsg = this.$t("common.validation.errors.seriaNoItemExist")
        return
      }
      this.itemsList.push(machineSerial)
    },
    removeItem(index) {
      this.itemsList.splice(index, 1)
    },
    onFileSelected(file) {
      this.currentFile = file
    }
  }
}
</script>

<style></style>
