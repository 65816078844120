<template>
  <v-card class="pa-0 pb-4 ma-4" color="white">
    <v-card-title class="text-uppercase">
      {{ title }}
    </v-card-title>
    <v-card-subtitle>
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-text>
      <p v-for="d in description" :key="d.content">
        {{ d.content }}
      </p>
    </v-card-text>

    <v-container fluid>
      <v-row>
        <v-col xs="auto" class="mr-0">
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            :label="$t('common.filters.filter')"
            hide-details
            class="ma-0 pa-0"
          ></v-text-field>
        </v-col>
        <v-col cols="auto" class="mx-0 px-0">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click="refreshItems"
                :loading="refreshLoading"
                :disabled="refreshLoading"
              >
                <v-icon>mdi-cached</v-icon>
                <template v-slot:loader>
                  <span class="refreshLoader">
                    <v-icon>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </template>
            <span>{{ $t("common.actions.refresh") }}</span>
          </v-tooltip>
        </v-col>

        <v-col v-if="this.canAddItem" cols="auto" class="ml-0 pl-0">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                icon
                @click="addItem"
                :disabled="refreshLoading"
                class="grab-add-user"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            <span v-if="this.routePath == '/documents/service_instructions'">{{
              $t("documents.serviceInstructions.action.add")
            }}</span>
            <span v-else>{{
              $t("documents.serviceBulletins.action.add")
            }}</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <ServiceDocumentFilter
            :availableProducts="availableProducts"
            :selectedProducts.sync="selectedProducts"
            @filterChanged="onFilterChanged"
            :disabled="refreshLoading"
            :isFiltered.sync="isFiltered"
            :clearFilter="clearFilter"
          />
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="serviceDocuments"
      :search="search"
      :expanded.sync="expanded"
      :loading="isDownloadingFile"
      hide-default-footer
      disable-pagination
      sort-by="name"
      sort-asc
      show-expand
      single-expand
      item-key="partNo"
      :custom-filter="customFilter"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <ServiceDocumentsSerials
            :items="jsonDataSerialArr"
            :partNo="item.partNo"
          />
        </td>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-menu left>
          <template v-slot:activator="{ on: menu }">
            <v-btn icon color="primary" v-on="menu">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="canEditItem" @click="editItem(item)">
              <v-list-item-title class="body-2">
                <v-icon small>mdi-pencil</v-icon>
                {{ $t("common.actions.edit") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="canRemoveItem" @click="removeItem(item)">
              <v-list-item-title class="body-2">
                <v-icon small>mdi-delete</v-icon
                >{{ $t("common.actions.remove") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="canDownloadItem" @click="downloadItem(item)">
              <v-list-item-title class="body-2">
                <v-icon small>mdi-download</v-icon
                >{{ $t("common.actions.download") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      top
      dark
      color="green darken-1"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar = false" v-bind="attrs">
          {{ $t("common.actions.close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Dialog to add a new or edit an existing service documents -->
    <ServiceDocumentsDialog
      :show="ShowDialog"
      :dialogAction="dialogAction"
      :docType="docType"
      :editArr="editArr"
      @save="onSave"
      @update="onUpdate"
      @cancel="onCancel"
    />

    <ConfirmRemoveDialog
      :itemName="this.itemName"
      confirmRemoveEvent="confirmEvent"
      :item="serviceDocumentsItem"
      removeAction="removeServiceDocuments"
      :show="showRemoveDialog"
      @close="showRemoveDialog = false"
      v-on:confirmEvent="removeServiceDocuments(serviceDocumentsItem.partNo)"
    />
  </v-card>
</template>

<script>
import fetchClient from "@/utils/fetchClient"
import ServiceDocumentsSerials from "@/components/ServiceDocumentsSerials"
import ServiceDocumentsDialog from "@/dialogs/ServiceDocumentsDialog"
import ConfirmRemoveDialog from "@/dialogs/ConfirmRemoveDialog"
import ServiceDocumentFilter from "@/components/filters/ServiceDocumentFilter"
import { mapActions } from "vuex"
import { MachineSerials } from "../../dialogs/ServiceDocumentsDialog.vue"

export default {
  components: {
    ServiceDocumentsDialog,
    ServiceDocumentsSerials,
    ConfirmRemoveDialog,
    ServiceDocumentFilter
  },

  data() {
    return {
      jsonDataArr: [],
      jsonDataSerialArr: [],
      jsonDataArrMerged: [],
      downloadingFiles: 0,
      search: "",
      expanded: [],
      refreshLoading: false,
      file: "",
      ShowDialog: false,
      serviceDocumentsItem: {},
      snackbar: false,
      snackbarText: "",
      showRemoveDialog: false,
      dialogAction: "",
      docType: "",
      itemName: "",
      isFiltered: false,
      selectedProducts: [],
      jsonServiceDocuments: "",
      editArr: [],
      clearFilter: ""
    }
  },

  mounted() {},
  created() {
    this.fetch()
    this.loadServiceDocumentFilter()
  },

  computed: {
    title() {
      return this.getTitle()
    },
    subtitle() {
      return this.getSubtitle()
    },
    description() {
      return this.getDescription()
    },
    headers() {
      return [
        {
          text: "",
          align: "right",
          sortable: false,
          value: "icon",
          width: 20
        },
        {
          text: this.$t("documents.serviceDocuments.datatable.headers.partNo"),
          align: "left",
          sortable: true,
          value: "partNo"
        },
        {
          text: this.$t(
            "documents.serviceDocuments.datatable.headers.description"
          ),
          align: "left",
          sortable: true,
          value: "description"
        },
        {
          text: this.$t(
            "documents.serviceDocuments.datatable.headers.machines"
          ),
          align: "left",
          sortable: true,
          value: "productIds"
        },
        {
          text: this.$t("common.dataTable.headers.actions"),
          align: "right",
          sortable: false,
          value: "action"
        }
      ]
    },
    isDownloadingFile() {
      return this.downloadingFiles > 0
    },
    canAddItem() {
      return this.$store.getters.isAdmin
    },
    canEditItem() {
      return this.$store.getters.isAdmin
    },
    canDownloadItem() {
      return this.$store.getters.isService || this.$store.getters.isAdmin
    },
    canRemoveItem() {
      return this.$store.getters.isAdmin
    },
    routePath() {
      return this.$route.path
    },
    availableProducts() {
      if (this.jsonDataSerialArr?.length > 0) {
        const groupedMachines = this.jsonDataSerialArr.reduce(
          (groupedMachines, machine) => {
            const group = groupedMachines[machine.productIds] || []
            group.push(machine)
            groupedMachines[machine.productIds] = group
            return groupedMachines
          },
          {}
        )

        const productKeys = Object.keys(groupedMachines)
        const products = []

        for (let ix = 0; ix < productKeys.length; ix++) {
          const prods = groupedMachines[productKeys[ix]]
          const productCount = prods.length

          const updatedProductIds = prods[0].productIds
            .replace("descsingle", "Descaler Single")
            .replace("desctwin", "Descaler Twin")

          products.push({
            id: prods[0].productIds,
            label: "Brokk " + updatedProductIds,
            value: prods[0].productIds,
            count: productCount
          })
        }

        return products
      }
      return []
    },
    serviceDocuments() {
      let serviceDocuments = this.jsonDataArrMerged

      if (serviceDocuments !== undefined && serviceDocuments !== null) {
        if (this.selectedProducts.length > 0) {
          let filteredProducts = serviceDocuments.filter(product => {
            const productIdsArr = product.productIds
              .split(",")
              .map(s => s.trim())
            return this.selectedProducts.some(filterItem => {
              const regex = new RegExp(`\\b${filterItem.id}\\b`)
              return productIdsArr.some(productId => productId.match(regex))
            })
          })

          return filteredProducts
        }
      }
      return serviceDocuments
    }
  },
  watch: {
    routePath() {
      this.fetch()
    }
  },
  methods: {
    ...mapActions(["setError"]),

    // ---- Fetch -----
    async fetch() {
      let url = ""

      if (this.routePath === "/documents/service_instructions") {
        url = "/api/v1/documents/serviceinstructions"
        this.clearFilter = "service_instructions"
      } else {
        url = "/api/v1/documents/servicebulletins"
        this.clearFilter = "servicebulletins"
      }

      this.jsonDataArr = []
      this.jsonDataSerialArr = []
      this.jsonDataArrMerged = []

      this.loading = true

      try {
        const jsonData = await fetchClient(this.$i18n).getDataOrThrow(url)

        const uniqueProducts = new Map()

        // --- Process partNo, description and productIds

        jsonData.forEach(item => {
          if (!uniqueProducts.has(item.partNo)) {
            uniqueProducts.set(item.partNo, {
              partNo: item.partNo,
              description: item.description,
              document: item.document,
              productIds: [item.productId]
            })
          } else {
            const existingProduct = uniqueProducts.get(item.partNo)
            if (!existingProduct.productIds.includes(item.productId)) {
              existingProduct.productIds.push(item.productId)
            }
          }
        })

        uniqueProducts.forEach(item => {
          let productIdsJoined = item.productIds
            .map(productId => {
              if (productId === "descsingle") {
                return "Descaler Single"
              } else if (productId === "desctwin") {
                return "Descaler Twin"
              } else if (productId === "spp") {
                return "SmartPower⁺"
              } else if (productId === "sp") {
                return "Demolition robots"
              }
              return productId
            })
            .join(", ")

          this.jsonDataArr.push({
            partNo: item.partNo,
            description: item.description,
            document: item.document,
            productIds: "Brokk " + productIdsJoined
          })
        })

        // --- Process productIds and serialNos

        const productIdSerials = new Map()

        jsonData.forEach(item => {
          if (!productIdSerials.has(item.partNo)) {
            productIdSerials.set(item.partNo, {
              partNo: item.partNo,
              products: new Map()
            })
          }

          const existingProduct = productIdSerials.get(item.partNo)
          if (!existingProduct.products.has(item.productId)) {
            existingProduct.products.set(item.productId, {
              productId: item.productId,
              serialNos: [item.serialNo]
            })
          } else {
            const existingProductId = existingProduct.products.get(
              item.productId
            )
            existingProductId.serialNos.push(item.serialNo)
          }
        })

        productIdSerials.forEach(product => {
          product.products.forEach(productId => {
            let updatedProductId = productId.productId
            updatedProductId = updatedProductId.replace(
              /descsingle/g,
              "Descaler Single"
            )
            updatedProductId = updatedProductId.replace(
              /desctwin/g,
              "Descaler Twin"
            )
            updatedProductId = updatedProductId
              .replace(/spp/g, "SmartPower⁺")
              .replace(/sp/g, "Demolition robots")

            this.jsonDataSerialArr.push({
              partNo: product.partNo,
              productIds: updatedProductId,
              serials: productId.serialNos.join(", ")
            })
          })
        })
      } catch (error) {
        this.setError(error)
      }

      this.jsonDataArrMerged = this.mergeArrays(
        this.jsonDataArr,
        this.jsonDataSerialArr
      )

      this.loading = false
    },
    addItem() {
      this.ShowDialog = true
      this.dialogAction = "Add"
      if (this.routePath === "/documents/service_instructions") {
        this.docType = "serviceInstructions"
      } else {
        this.docType = "serviceBulletins"
      }
    },
    async removeServiceDocuments(partNo) {
      this.errorMsg = ""
      let messageText = ""

      if (this.routePath === "/documents/service_instructions") {
        this.docType = "serviceInstructions"
        messageText =
          "documents.serviceInstructions.dialogs.errors.couldNotRemoveServiceInstructions"
      } else {
        this.docType = "serviceBulletins"
        messageText =
          "documents.serviceBulletins.dialogs.errors.couldNotRemoveServiceInstructions"
      }
      try {
        await this.$store.dispatch("removeServiceDocuments", {
          docType: this.docType,
          partNo: partNo
        })
      } catch (errMsg) {
        this.errorMsg = this.$t(messageText, { msg: errMsg })
      }

      await this.refreshItems()
    },
    // ---------------------------------------------
    async onSave() {
      this.ShowDialog = false
      let snackBarText = ""

      if (this.routePath === "/documents/service_instructions") {
        snackBarText = "documents.serviceInstructions.messages.itemAdded"
      } else {
        snackBarText = "documents.serviceBulletins.messages.itemAdded"
      }

      this.snackbarText = this.$t(snackBarText)
      this.snackbar = true
      this.dialogAction = ""
      await this.refreshItems()
    },
    async onUpdate() {
      this.ShowDialog = false
      this.snackbarText = this.$t(
        "documents.serviceInstructions.messages.itemUpdated"
      )
      this.snackbar = true
      this.dialogAction = ""
      await this.refreshItems()
    },
    onCancel() {
      this.ShowDialog = false
      this.refreshItems()
      this.dialogAction = ""
    },
    async downloadItem(item) {
      if (!item.locked) {
        this.downloadingFiles += 1
        item.locked = true

        let errorData = null
        let downloadPath = ""
        if (this.routePath === "/documents/service_instructions") {
          downloadPath =
            "/api/v1/downloads/open/service_instructions/" + item.document
        } else {
          downloadPath =
            "/api/v1/downloads/open/service_bulletins/" + item.document
        }

        try {
          const response = await fetchClient(this.$i18n).getResponse(
            "GET",
            downloadPath
          )

          if (response.status === 200) {
            const responseData = await response.arrayBuffer()
            const blob = new Blob([responseData], {
              type: response.headers.get("content-type")
            })

            let link = document.createElement("a")
            link.href = window.URL.createObjectURL(blob)
            link.download = item.document
            link.click()
          } else {
            errorData = await fetchClient(this.$i18n).getError(response)
          }
        } catch (error) {
          errorData = {
            message: this.$t(
              "documents.serviceInstructions.messages.failedToDownload",
              {
                file: downloadPath
              }
            ),
            causedBy: error.toString(),
            details: error.message
          }
        } finally {
          item.locked = false
          this.downloadingFiles -= 1

          if (errorData != null) {
            errorData.requestMethod = "GET"
            errorData.requestUri = downloadPath

            const err = {
              response: {
                data: errorData
              }
            }
            this.$store.dispatch("setError", err)
          }
        }
      }
    },

    // ---------------------------------------------

    getTitle() {
      let title = ""
      if (this.routePath === "/documents/service_instructions") {
        title = this.$t("documents.menuItems.serviceInstructions")
      } else {
        title = this.$t("documents.menuItems.serviceBulletins")
      }
      return title
    },
    getSubtitle() {
      let subtitle = ""
      if (this.routePath === "/documents/service_instructions") {
        subtitle = this.$t("documents.serviceInstructions.subtitle")
      } else {
        subtitle = this.$t("documents.serviceBulletins.subtitle")
      }
      return subtitle
    },
    getDescription() {
      let descriptionArray = []
      if (this.routePath === "/documents/service_instructions") {
        descriptionArray.push({
          content: this.$t("documents.serviceInstructions.description")
        })
      } else {
        descriptionArray.push({
          content: this.$t("documents.serviceBulletins.description")
        })
      }
      return descriptionArray
    },
    currentUser() {
      return this.$store.getters.loggedInUser
    },
    async refreshItems() {
      this.refreshLoading = true
      await this.fetch()
      this.refreshLoading = false
      this.loadServiceDocumentFilter()
    },
    onFileSelected(event) {
      this.file = event.target.files[0]
    },
    removeItem(item) {
      this.serviceDocumentsItem = Object.assign({}, item)
      if (this.routePath === "/documents/service_instructions") {
        this.itemName = this.$t("documents.serviceInstructions.itemName")
      } else {
        this.itemName = this.$t("documents.serviceBulletins.itemName")
      }
      this.showRemoveDialog = true
    },
    async editItem(item) {
      this.ShowDialog = true
      this.partNo = item.partNo
      let url = ""
      if (this.routePath === "/documents/service_instructions") {
        this.docType = "serviceInstructions"
        url = `/api/v1/documents/serviceinstructions/${item.partNo}`
      } else {
        this.docType = "serviceBulletins"
        url = `/api/v1/documents/servicebulletins/${item.partNo}`
      }

      this.loading = true

      try {
        const jsonData = await fetchClient(this.$i18n).getDataOrThrow(
          encodeURI(url)
        )
        const {
          partNo,
          document,
          description,
          added,
          products
        } = this.filterData(jsonData)

        this.dialogAction = "Edit"
        this.editArr[0] = document
        this.editArr[1] = partNo
        this.editArr[2] = description
        this.editArr[3] = added
        this.editArr[4] = products
      } catch (error) {
        this.setError(error)
      }

      this.loading = false
    },
    filterData(filteredData) {
      const result = {
        partNo: filteredData[0].partNo,
        document: filteredData[0].document,
        description: filteredData[0].description,
        added: filteredData[0].added,
        products: filteredData
          .filter(item => item !== null)
          .map(
            obj =>
              new MachineSerials(
                `Brokk ${obj.productId}`,
                obj.productId,
                obj.serialNo
              )
          )
      }

      return result
    },
    mergeArrays(arr1, arr2) {
      let arr3 = []

      arr1.forEach(item1 => {
        let newItem = { ...item1 }
        arr2.forEach(item2 => {
          if (item1.partNo === item2.partNo) {
            if (newItem.serials) {
              newItem.serials += "," + item2.serials
            } else {
              newItem.serials = item2.serials
            }
          }
        })
        arr3.push(newItem)
      })
      return arr3
    },
    checkSerialsFormat(serials, x) {
      const segments = serials.split(",")
      let result = false

      for (let i = 0; i < segments.length; i++) {
        const segment = segments[i].trim()

        if (segment.includes("-")) {
          const [lowerBound, upperBound] = segment.split("-")
          const lowerBoundInt = parseInt(lowerBound, 10)
          const upperBoundInt = parseInt(upperBound, 10)

          // Check if search value matches a portion of the range
          if (
            x.toString().length <= lowerBound.length &&
            x.toString() >= lowerBound.slice(0, x.toString().length) &&
            x.toString() <= upperBound.slice(0, x.toString().length)
          ) {
            result = true
            break
          }

          // Check if search value is within the range
          if (x >= lowerBoundInt && x <= upperBoundInt) {
            result = true
            break
          }
        } else {
          const segmentStart = segment.slice(0, x.toString().length)
          if (segmentStart === x.toString()) {
            result = true
            break
          }
        }
      }

      return result
    },

    customFilter(value, search, item) {
      const searchLower = search.toLowerCase()

      const partNoMatch = item.partNo.toLowerCase().includes(searchLower)
      const descriptionMatch = item.description
        .toLowerCase()
        .includes(searchLower)
      const documentMatch = item.document.toLowerCase().includes(searchLower)
      const productIdsMatch = item.productIds
        .toLowerCase()
        .includes(searchLower)

      const serialsArray = item.serials.split(",")
      const serialsMatch = serialsArray.some(serial =>
        this.checkSerialsFormat(serial, parseInt(search, 10))
      )

      return (
        partNoMatch ||
        descriptionMatch ||
        documentMatch ||
        productIdsMatch ||
        serialsMatch
      )
    },
    onFilterChanged() {
      this.saveServiceDocumentFilter()
    },
    loadServiceDocumentFilter() {
      const filterString = localStorage.getItem("machinesFilter")
      if (filterString) {
        const filterItem = JSON.parse(filterString)
        this.selectedProducts = filterItem?.selectedProducts || []
      }
    },
    saveServiceDocumentFilter() {
      const filterItem = {
        selectedProducts: this.selectedProducts
      }
      localStorage.setItem("machinesFilter", JSON.stringify(filterItem))
    }
  }
}
</script>

<style></style>
